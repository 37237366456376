import leccion1_0 from '../content/courses/basic-course/images/leccion1-0.png'
import leccion1_1 from '../content/courses/basic-course/images/leccion1-1.png'
import leccion1_2 from '../content/courses/basic-course/images/leccion1-2.png'
import leccion2_1 from '../content/courses/basic-course/images/leccion2-1.png'
import leccion3_1 from '../content/courses/basic-course/images/leccion3-1.png'
import leccion3_2 from '../content/courses/basic-course/images/leccion3-2.png'
import leccion4_1 from '../content/courses/basic-course/images/leccion4-1.png'
import leccion4_2 from '../content/courses/basic-course/images/leccion4-2.png'
import leccion4_3 from '../content/courses/basic-course/images/leccion4-3.png'
import leccion5_1 from '../content/courses/basic-course/images/leccion5-1.png'
import leccion5_2 from '../content/courses/basic-course/images/leccion5-2.png'
import leccion5_3 from '../content/courses/basic-course/images/leccion5-3.png'
import leccion6_1 from '../content/courses/basic-course/images/leccion6-1.png'
import leccion6_2 from '../content/courses/basic-course/images/leccion6-2.png'
import leccion7_1 from '../content/courses/basic-course/images/leccion7-1.png'
import leccion7_2 from '../content/courses/basic-course/images/leccion7-2.png'
import marketPic from '../assets/market.png'

const naddr_es = 'naddr1qqjx2c3ev4snveph95erzwfh956xxdr994skzvr995mkzwf3v4snqerp8p3n2qg6waehxw309aex2mrp0yh8xct5daeks6tyde3jucm0d5pzpmmgnmsg2g7875d864xp4vp0dpd2xg8jphl9w70xp7jr3azf2k55qvzqqqr4gvjj6jhm'

const init_es = (db) => { db['es-PY'] = {
  '{M}/{D}/{Y} {hh}:{mm}': '{D}/{M}/{Y} {hh}:{mm}',
  'Block Clock': 'Reloj bloque',
  'Today is the ': 'Hoy es el ',
  ' day of the ': ' día del ',
  ' month of the ': ' mes del ',
  ' year of Bitcoin in block time': ' año de Bitcoin en tiempo bloque',
  'ordinal-and': ' ',
  'first': 'primero',
  'second': 'segundo',
  'third': 'tercero',
  'fourth': 'cuarto',
  'fifth': 'quinto',
  'sixth': 'sexto',
  'seventh': 'séptimo',
  'eighth': 'octavo',
  'ninth': 'noveno',
  'tenth': 'décimo',
  'eleventh': 'undécimo',
  'twelfth': 'duodécimo',
  'thirteenth': 'decimotercero',
  'fourteenth': 'decimocuarto',
  'fifteenth': 'decimoquinto',
  'sixteenth': 'decimosexto',
  'seventeenth': 'decimoséptimo',
  'eighteenth': 'decimoctavo',
  'nineteenth': 'decimonoveno',
  'twentieth': 'vigésimo',
  'thirtieth': 'trigésimo',
  'fortieth': 'cuadragésimo',
  'fiftieth': 'quincuagésimo',
  'sixtieth': 'sexagésimo',
  'seventieth': 'septuagésimo',
  'eightieth': 'octogésimo',
  'ninetieth': 'nonagésimo',
  'ord-twenty': 'vigésimo',
  'ord-thirty': 'trigésimo',
  'ord-forty': 'cuadragésimo',
  'ord-fifty': 'quincuagésimo',
  'ord-sixty': 'sexagésimo',
  'ord-seventy': 'septuagésimo',
  'ord-eighty': 'octogésimo',
  'ord-ninety': 'nonagésimo',
  'events': 'eventos',
  'ASU Meetup': 'ASU Reunión',
  'CDE Meetup': 'CDE Reunión',
  'DNC Reunión': 'DNC Reunión',
  'DNC Reunión (inglés)': 'DNC Reunión (inglés)',
  'DNC Reunión (alemán)': 'DNC Reunión (alemán)',
  'DNC Reunión (castillano)': 'DNC Reunión (castillano)',
  'ENC Meetup': 'ENC Reunión',
  'library': 'biblioteca',
  'date': 'fecha',
  'price': 'precio',
  'location': 'ubicación',
  'technical consultation': 'consulta técnica',
  'Schedule a visit or video call to learn how we can serve you.': 'Concierte una visita o videollamada para saber cómo podemos servirle.',
  'read full article': 'leer el artículo completo',
  'learn more': 'saber más',
  'inquire now': 'infórmese ahora',
  'purchase now': 'comprar ahora',
  'product details': 'detalles del producto',
  'browse now': 'navegar ahora',
  'begin the course': 'iniciar el curso',
  'register now': 'inscríbete ahora',
  'get it now': 'consíguelo ahora',
  'try it now': 'pruébelo ahora',
  'see features': 'ver funciones',
  'see apps': 'ver aplicaciones',
  'course details': 'detalles del curso',
  'learn': 'aprende',
  'courses': 'cursos',
  'apps': 'apps',
  'nuevoguarani': 'nuevoguarani',
  'wallet': 'monedero',
  'why': 'por qué',
  'services': 'servicios',
  'meetup': 'reunión',
  'market': 'mercado',
  'contact': 'contacto',
  'basics': 'basicos',
  'lesson': 'lección',
  'next': 'siguiente',
  'contact form': 'formulario de contacto',
  'How would you like to be contacted?': '¿Cómo desea que nos pongamos en contacto con usted?',
  'What is your message?': '¿Cuál es su mensaje?',
  'optional': 'opcional',
  'required': 'obligatorio',
  'nostr': 'nostr',
  'name': 'nombre',
  'e-mail': 'correo electrónico',  
  'message': 'mensaje',
  'send': 'enviar',
  'check required fields': 'marque los campos obligatorios',
  'or': 'o',
  'Questions?': '¿Preguntas?',
  'ask now': 'pregunte ahora',
  'Bugs?': 'Errores?',
  'report a bug': 'informar de un error',
  'Hi, I would like to ask a question about': 'Hola, me gustaría hacer una pregunta sobre',
  'techcons:question': 'Hola, me gustaría hacer una pregunta sobre consulta técnica.\n...\n',
  'webdev:question': 'Hola, me gustaría hacer una pregunta sobre desarrollo web.\n...\n',
  'compfix:question': 'Hola, me gustaría hacer una pregunta sobre reparación informática.\n...\n',
  'Educational Resources': 'Recursos educativos',
  'Copyright © 2023. All rights reserved.': 'Copyright © 2023-2024. Todos los derechos reservados.',
  'dates': 'fechas',
  'schedule': 'programa',
  'course options': 'opciones del curso',
  'publication date': 'fecha de publicación',
  'inquire about check-out': 'solicitar el préstamo',
  'this course is for': 'este curso es para',
  'in this course, you will': 'en este curso, usted podrá',
  'this course includes': 'este curso incluye',
  'NOT included in course': 'NO incluido en el curso',
  'prerequisites': 'requisitos previos',
  'payment methods': 'formas de pago',
  'registration': 'inscripción',
  'everyone': 'todos',
  'owners': 'propietarios',
  'business owners': 'empresarios',
  'owners of businesses which already have delivery services': 'propietarios de empresas que ya disponen de servicios de reparto',
  'all employees who will handle online orders': 'todos los empleados que gestionen pedidos en línea',
  'high net worth individuals': 'particulares con grandes patrimonios',
  'individuals with savings': 'personas con ahorros',
  'individuals with income to spare': 'personas con ingresos de sobra',
  'none': 'ninguno',
  'Guarani cash': 'Guaraní, efectivo',
  'Guarani, bank deposit': 'Guaraní, depósito bancario',
  'Dollars, PayPal': 'Dólares, PayPal',
  'Dollars, bank deposit': 'Dólares, depósito bancario',
  'in-person, upon payment': 'en persona, previo pago',
  'online, effective upon receipt of payment': 'en línea, a partir de la recepción del pago',

  'Featured video!': '¡Vídeo destacado!',
  'Click to watch now!': '¡Haga clic para verlo ahora!',


  /* LIBRARY PAGE */

  'library:title': 'Biblioteca Bitcoin comunitaria',
  'library:intro': 'Disponemos de los siguientes libros físicos en nuestra biblioteca. Para acceder a un libro, póngase en contacto con nosotros. {link:lightning:biblioteca@satoshidnc.com^Aceptamos donaciones} para sufragar los gastos de adquisición de estos y otros libros que pondremos a disposición de nuestra comunidad.',
  'library:other-title': 'Recursos adicionales',
  'library:other-intro': 'Los siguientes libros de Nelson Cardozo están disponibles en línea.',

  'el-patron-bitcoin:title': 'El patrón Bitcoin',
  'el-patron-bitcoin:subtitle': 'La alternativa descentralizada a los bancos centrales',
  'el-patron-bitcoin:author': 'Saifedean Ammous (Autor), Mercedes Vaquero Granados (Traductor)',
  'el-patron-bitcoin:date': '2 de octubre de 2018',
  'el-patron-bitcoin:link': '/contacto?n=1&m=Estimado%20señor%2C%20Tengo%20entendido%20que%20usted%20tiene%20el%20libro%20"El%20patrón%20Bitcoin"%20disponible%20en%20el%20Distrito%20de%20Nueva%20Colombia.%20Me%20gustaría%20tomarlo%20prestado%2C%20por%20favor.%20Puede%20ponerse%20en%20contacto%20conmigo%20utilizando%20la%20información%20de%20contacto%20que%20le%20he%20facilitado.%20Gracias.',

  'conociendo-bitcoin:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin:subtitle': '¿Se puede llegar a comprender todo sobre bitcoin? Se se puede.',
  'conociendo-bitcoin:author': 'Nelson Cardozo (Autor)',
  'conociendo-bitcoin:date': '',

  'conociendo-bitcoin-ii:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin-ii:subtitle': 'Orígens del dinero, que es bitcoin, aprende a ahorrar con bitcoins',
  'conociendo-bitcoin-ii:author': 'Nelson Cardozo (Autor)',
  'conociendo-bitcoin-ii:date': '',

  'meetup:title': '¡Bienvenido!',
  'meetup:location': 'distrito de Nueva Colombia',
  'meetup:date': 'domingo, 15 de setiembre, a las 10:00 horas.',
  'meetup:price': '5 Gs (efectivamente nada), pagados a través de la red Relámpago (Lightning).',
  'meetup:content.1': 'Por favor, inscríbase para asistir (será necesario registrarse). Esto inscripción nos ayuda a planificar y a mantener al grupo centrado en participar activamente en la economía circular del bitcoin.',
  'meetup:content.2': 'Para la ubicación exacta y/o asistencia en la inscripción, por favor consultar vía {link:https://chat.whatsapp.com/KiFnEElpETfH308PtN47Yr^WhatsApp}.',

  'Bitcoin Courses': 'Cursos sobre Bitcoin',
  'courses:intro': 'El cursos de Satoshi están enfocados en la misión de facilitar el conocimiento y la adopción de Bitcoin en Paraguay para el beneficio mutuo de los negocios locales, individuos, visitantes extranjeros e inmigrantes. Juntos estamos construyendo la economía del futuro en Paraguay. Para entender el contexto de nuestra misión, por favor lea nuestra breve introducción:',
  'Course Selection': 'Selección de cursos',
  'Course Selection.1': 'Cada uno de estos cursos se centra en un caso de uso específico. Creemos que de este modo se proporcionan las vías óptimas para el éxito de nuestros clientes y de la comunidad. Revise las descripciones de los cursos y seleccione el que mejor se adapte a su situación particular. Si tiene alguna pregunta, póngase en contacto con nosotros. También puede interesarle programar una consulta individual para casos en los que no se prefiera o no esté disponible la formación en grupo.',
  'Course Selection.2': '(Nota para los cursos de día completo: NO se proporcionan comidas. Animamos a traer su propio almuerzo o explorar los restaurantes locales).',
  'Refund Policy': 'Política de reembolso',
  'Refund Policy.1': 'Nos esforzamos por ofrecer los mejores servicios completos de formación y apoyo posibles para el cuidado a largo plazo de nuestros clientes. Su éxito es la medida de nuestro éxito. Estamos seguros de que quedará impresionado por el contenido y la calidad de nuestros cursos.',
  'Refund Policy.2': 'En caso de que un curso deba cancelarse por cualquier motivo antes de la fecha de inicio, haremos todo lo posible por reembolsar las inscripciones. Sin embargo, tenga en cuenta que para los cursos que incluyen hardware, no podemos reembolsar los costes del equipo; el equipo adquirido le pertenece a usted. Los reembolsos después de la fecha de inicio están sujetos a la discreción de Satoshi. Gracias por su comprensión.',

  'basics-course:date': '14 de set - 7 de oct de 2023',
  'basics-course:title': 'Conceptos básicos de Bitcoin',
  'basics-course:blurb': 'Te hace inteligente sobre Bitcoin con todo lo esencial para entender cómo funciona Bitcoin.',
  'basics-course:subtitle': 'Se trata de un curso de tres semanas que cubre los aspectos básicos de Bitcoin.',
  'basics:option1': 'Horario A: 1 hora diaria durante tres semanas completas, de 8:00 a 9:00 horas.',
  'basics:option2': 'Horario B: 3 días completos, un día a la semana (en fin de semana)',
  'basics:bullet1': 'Aprenda todo lo que necesita saber para comenzar su viaje Bitcoin.',
  'basics:bullet2': 'Aprenda términos, conceptos y vocabulario para hablar y entender a los Bitcoiners.',
  'basics:bullet3': 'Realiza tu primer cambio de Guarani a Bitcoin.',
  'basics:bullet4': 'Realiza tu primera compra utilizando Bitcoin (valor de 10.000 Gs, incluido).',

  'business-course:date': 'pendiente de determinar',
  'business-course:title': 'Bitcoin para empresas',
  'business-course:blurb': 'Equipa a los propietarios de negocios con herramientas de punto de venta y formación en Paraguay.',
  'business-course:subtitle': 'Este curso dota a los empresarios de herramientas y formación sobre puntos de venta en Paraguay.',
  'business:option1': '2 días completos, programados individualmente 1 día fuera de las instalaciones, 1 día en las instalaciones',
  'business:bullet1': 'Sistema de punto de venta de Bitcoin y dinero guaraní',
  'business:bullet2': 'Formación de cajeros sólo para el propietario/registrante (el propietario formará fácilmente a otros cajeros)',
  'business:bullet3': 'Un mes de servicio de monedero (puede prorrogarse si no se utiliza el Curso para Constructores)',
  'business:bullet4': 'Todo lo que necesita para vender en Bitcoin',
  'business:bullet5': 'Un cartel para su tienda indicando que acepta pagos con Bitcoin/Lightning',
  'business:bulletx1': 'Formación para otros cajeros (el propietario formará fácilmente a otros cajeros)',
  'business:bulletx2': 'Servicio continuo de monedero (puede adquirirse por separado, si no se utiliza el Curso Builders)',
  'business:bulletx3': 'Material cubierto en el Curso Básico (muy recomendable pero no obligatorio)',
  'business:prereq1': 'Smartphone compatible u otro dispositivo para el punto de venta (puede incluirse con coste adicional si se desea)',
  'business:prereq2': 'Internet en el punto de venta',

  'distros-course:date': 'pendiente de determinar',
  'distros-course:title': 'Bitcoin para distribuidores',
  'distros-course:blurb': 'Enseña a los distribuidores a tomar pedidos en línea utilizando Bitcoin en Paraguay.',
  'distros-course:subtitle': 'Este curso enseña a los distribuidores cómo tomar pedidos en línea usando Bitcoin en Paraguay.',
  'distros:option1': 'Un día completo',
  'distros:bullet1': 'Formación sobre el sistema de pedidos en línea',
  'distros:bullet2': 'Un mes de servicio de monedero (puede prorrogarse si no se utiliza el Curso para Constructores)',
  'distros:bullet3': 'Mercado en línea',
  'distros:bullet4': 'Todo lo que necesita para gestionar una tienda online que acepte Bitcoin',
  'distros:bulletx1': 'Introducción de datos para su línea de productos (puede contratarse por separado)',
  'distros:bulletx2': 'Servicio continuo de monedero (puede adquirirse por separado si no se utiliza el Curso Builders)',
  'distros:bulletx3': 'Material cubierto en el Curso Básico (muy recomendable pero no obligatorio)',
  'distros:prereq1': 'Ordenador u otro dispositivo compatible',
  'distros:prereq2': 'Internet en el punto de recepción de pedidos',

  'builders-course:date': 'pendiente de determinar',
  'builders-course:title': 'Bitcoin para constructores',
  'builders-course:blurb': 'Te guía en la construcción de tu propio nodo Bitcoin y Lightning en Paraguay.',
  'builders-course:subtitle': 'Este curso lleva a los estudiantes a construir su propio nodo Bitcoin y Lightning en Paraguay.',
  'builders:option1': '1 hora al día durante tres semanas completas, de 8:00 a 9:00.',
  'builders:bullet1': 'Nodo Bitcoin construido por un estudiante',
  'builders:bullet2': 'Copia completa de la cadena de bloques de Bitcoin (500 GB de ahorro en descargas)',
  'builders:bullet3': 'Nodo de rayos de autocustodia',
  'builders:bullet4': 'Canal de pago relámpago financiado y equilibrado',
  'builders:bullet5': 'Todo lo que necesita para mantener su nodo',
  'builders:bulletx1': 'Equilibrio continuo de los canales (porque son los fondos del propietario)',
  'builders:bulletx2': 'Mantenimiento (puede adquirirse por separado)',
  'builders:prereq1': 'Curso básico (o prueba)',
  'builders:prereq2': 'Internet en el lugar previsto del nodo',

  'investors-course:date': 'pendiente de determinar',
  'investors-course:title': 'Bitcoin para inversores',
  'investors-course:blurb': 'Orienta a los inversores sobre las distintas formas de invertir en Bitcoin en Paraguay.',
  'investors-course:subtitle': 'Este curso orienta a los inversores sobre las distintas formas de invertir en Bitcoin en Paraguay.',
  'investors:option1': '3 días completos, un día por semana (domingo)',
  'investors:bullet1': 'El material del curso básico, con un énfasis diferente',
  'investors:bullet2': 'Instrucción sobre los protocolos de seguridad de Bitcoin',
  'investors:bullet3': 'Instrucción sobre la minería de Bitcoin como inversión',
  'investors:bullet4': 'Sus primeros 50 $ invertidos en Bitcoin de autocustodia (puede añadir más)',

  'basics:subtitle': 'Este es un curso de tres semanas sobre los fundamentos de Bitcoin.',
  'basics:desc1': 'Esta es una traducción al español del curso de 21 días de ',
  'basics:desc2': 'Bitcoin Magazine',
  'basics:desc3': ' (revista Bitcoin) en inglés.',
  'Week {n}:': 'Semana {n}:',

  'Magic Internet Money': 'Dinero mágico en Internet',
  '(1:intro)': '',
  '(1:intro).1': `img:[Dinero mágico en Internet](${leccion1_0})`,
  'A BRIEF HISTORY': 'UNA BREVE HISTORIA',
  'A BRIEF HISTORY.1': `El 3 de enero de 2009, un genio seudónimo llamado Satoshi Nakamoto inventó oficialmente Bitcoin.`,
  'A BRIEF HISTORY.2': `Quienquiera que sea esta persona o grupo misterioso, se las arregló para crear la primera criptomoneda del mundo que pronto cambiaría todo tal y como lo conocemos. Definido formalmente, Bitcoin (con “B” mayúscula) es un protocolo global, sin fronteras, descentralizado que permite el intercambio entre pares de la moneda bitcoin (“b” minúscula), que tiene una oferta máxima fija y una tasa de emisión conocida y decreciente.`,
  'A BRIEF HISTORY.3': `Aunque Bitcoin pueda parecer mágico, no pretende hacer nada innovador, sino ofrecer una alternativa mejorada al actual sistema financiero, injusto, inaccesible e inflacionista. Al abrir las finanzas, estamos tomando un sistema tan complejo e indescifrable que bloquea a casi dos mil millones de personas en todo el mundo, y convirtiéndolo en una red transparente y sin permisos de la que cualquiera puede formar parte.`,
  'A BRIEF HISTORY.4': `img:[Bitcoin: Un systema de effectivo electronico entre iguales](${leccion1_1})`,
  'WHAT DOES BITCOIN SOLVE?': '¿QUÉ RESUELVE BITCOIN?',
  'WHAT DOES BITCOIN SOLVE?.1': 'Centralización: Bitcoin alivia la necesidad de un sistema centralizado de terceros -como una compañía de tarjetas de crédito o un banco central- para confirmar y validar las transacciones. En lugar de requerir que el sistema financiero actual actúe como intermediario en nuestras transferencias y liquidaciones, Bitcoin funciona puramente de igual a igual, eliminando la necesidad de confiar en un controlador gubernamental centralizado.',
  'WHAT DOES BITCOIN SOLVE?.2': 'Verificabilidad: Bitcoin permite una validación de la moneda a nivel unitario que no es posible con el dinero fiduciario (respaldado por el gobierno). Por ejemplo, hay un montón de billetes de dólar falsos en circulación (el Tesoro de EE.UU. estima que uno de cada 10.000 billetes es falso) que la persona promedio no logra descubrir. Sin embargo, nadie puede crear bitcoins falsos porque la red Bitcoin está protegida criptográficamente mediante una cadena de bloques pública a la que cualquiera puede acceder y validar cualquier cantidad de bitcoins como real.',
  'WHAT DOES BITCOIN SOLVE?.3': 'Inflación: La oferta de Bitcoin está limitada a 21 millones. Nunca habrá más bitcoins. Nadie puede “imprimir más bitcoin” como hacemos actualmente con los dólares, inflando la oferta monetaria. A diferencia de las monedas fiduciarias, el bitcoin no reduce tu poder adquisitivo con el tiempo.',
  'WHAT DOES BITCOIN SOLVE?.4': '“No entiendo nada de lo que acabas de decir.”',
  'WHAT DOES BITCOIN SOLVE?.5': 'No se preocupe, si conceptos como “cadena de bloques” y “descentralización” le resultan confusos ahora mismo, se lo explicaré todo con detalle en los próximos 21 días. La verdad es que no necesitamos profundizar demasiado en cómo funcionan exactamente los aspectos técnicos de Bitcoin. Sin embargo, haces bien en mostrarte escéptico si sólo te digo que confíes en que funciona. Al fin y al cabo, el objetivo de Bitcoin es eliminar la necesidad de confiar.',
  'WHAT DOES BITCOIN SOLVE?.6': 'Pero... ¿cómo puede ser que no necesite confiar en nadie? ¿Cómo sé que Bitcoin no es una estafa? ¿Me hará rico el bitcoin? ¿Cuál es el valor intrínseco de bitcoin? Debe haber alguien gestionando Bitcoin, ¿verdad?',
  'WHAT DOES BITCOIN SOLVE?.7': 'Todas ellas son preguntas válidas que pronto tendrán una respuesta que, esperemos, cualquiera pueda entender. En esta conferencia introductoria, explicaremos brevemente cómo funciona bitcoin. Durante las próximas tres semanas, aprenderás todo, desde cómo hacer tu primera transacción con bitcoin hasta cómo bitcoin ya está aportando libertad financiera global a millones de personas en todo el mundo.',
  'THE BITCOIN BLOCKCHAIN': 'LA CADENA DE BLOQUES DE BITCOIN',
  'THE BITCOIN BLOCKCHAIN.1': 'Ah, sí. La mística y todopoderosa cadena de bloques que supuestamente está revolucionando la industria tecnológica en estos momentos. Aunque pueda parecer desalentador tratar de entender qué es una cadena de bloques y cómo funciona, básicamente ya sabes lo que es: el nombre lo delata.',
  'THE BITCOIN BLOCKCHAIN.2': `img:[La cadena de bloques de Bitcoin](${leccion1_2})`,
  'THE BITCOIN BLOCKCHAIN.3': 'Yes, it is literally a chain of (digital) blocks that holds data as a publicly visible ledger. Its history and validity are verified by Bitcoin full nodes across the globe that each keep a full copy of the blockchain history.',
  'THE BITCOIN BLOCKCHAIN.4': 'Because transactions are secured by the public blockchain, there is no need for an intermediary source of trust in order to confirm that your coins are real or that your transactions aren’t fraudulent; the publicly verifiable, mathematically programmed, cryptographically sound Bitcoin blockchain is the only proof you need.',
  'BITCOIN IS SOUND MONEY': 'BITCOIN ES DINERO SEGURO',
  'BITCOIN IS SOUND MONEY.1': `No es ningún secreto que el sistema financiero actual está profundamente roto. Con una hiperinflación galopante, la desigualdad económica mundial y la dependencia de los Estados-nación que detentan el poder mundial, el dinero fiduciario no está realmente respaldado por nada sólido, sino que es un producto del poder y el control.`,
  'BITCOIN IS SOUND MONEY.2': `Si queremos escapar al control de los poderes fácticos, necesitaremos un sistema alternativo. Bitcoin es una moneda sin autoridad central. Ningún gobierno puede controlarla, por lo que no va a sufrir las interminables rondas de flexibilización cuantitativa o cualquier otro esquema de impresión de dinero que empleen los gobiernos.`,
  'BITCOIN IS SOUND MONEY.3': `¿Podría ser esta la solución monetaria que hemos estado buscando?`,

  'Who Is In Charge Of Bitcoin?': '¿Quién manda en Bitcoin?',
  '(2:intro)': '',
  '(2:intro).1': 'Entonces, ¿quién está a cargo de Bitcoin?',
  '(2:intro).2': 'Esta es una de las primeras preguntas que suelen hacerse los novatos en bitcoin. Naturalmente, esta pregunta surge como una preocupación sobre la confianza: ¿Quién está en la cima de Bitcoin? ¿Es una estafa?',
  '(2:intro).3': 'La respuesta es sencilla: Bitcoin no está controlado por ningún país, entidad o persona. Está descentralizado, lo que significa que no puede ser corrompido o controlado - y eso es algo hermoso.',
  'WHY DO WE NEED DECENTRALIZATION?': '¿POR QUÉ NECESITAMOS LA DESCENTRALIZACIÓN?',
  'WHY DO WE NEED DECENTRALIZATION?.1': 'Quizás el aspecto más importante de Bitcoin es su descentralización; ésta es la principal diferencia que separa a Bitcoin del sistema bancario central al que estamos acostumbrados actualmente. Intrínsecamente, nuestro sistema bancario mundial está centralizado porque nuestro dinero está controlado y distribuido por el gobierno. Llamamos a este dinero “fiat” - originado de la palabra latina que significa: “una determinación por la autoridad”.',
  'WHY DO WE NEED DECENTRALIZATION?.2': 'Cuando se tiene una moneda fiduciaria centralizada como el dólar estadounidense (la actual moneda de reserva mundial), se está obligado a entrar en un sistema financiero que puede cambiar las reglas en cualquier momento. Estás sometido al gran sistema bancario que te cobra comisiones por descubierto, exige saldos mínimos y presta tu dinero a otras personas sólo para pagarte menos del 1% de interés anual.',
  'WHY DO WE NEED DECENTRALIZATION?.3': 'And while big banks in the United States have healthy competition and therefore have incentives to offer better, freer services to consumers, many countries around the world don’t offer this privilege. In fact, many banks overseas actually charge their consumers to keep their money in the bank.',
  'WHY DO WE NEED DECENTRALIZATION?.4': 'Por mucho que nos guste bromear al respecto, el uso de huchas físicas e inseguras es una realidad para más de mil millones de personas en todo el mundo que siguen sin tener acceso a servicios bancarios.',
  'WHY DO WE NEED DECENTRALIZATION?.5': 'Los servicios financieros crean barreras costosas como comisiones, mínimos y requisitos de identificación que no permiten a la gente participar en ellos de forma justa. Además, no todo el mundo tiene acceso a un sistema bancario centralizado, seguro y competente, sino que debe participar en el que le ofrece su gobierno, por muy inestable o corrupto que sea.',
  'WHAT FIXES THIS?': '¿QUÉ ARREGLA ESTO?',
  'WHAT FIXES THIS?.1': 'Hay un dicho en la comunidad Bitcoin: Bitcoin arregla esto.',
  'WHAT FIXES THIS?.2': 'Como nadie está a cargo de Bitcoin, Bitcoin funciona para todos. En lugar de tener a alguien en la cima, la red Bitcoin se basa en el consenso de todos los que participan en ella.',
  'WHAT FIXES THIS?.3': 'No importa en qué país estés o lo controlador que sea tu gobierno - tu riqueza está asegurada por la cadena de bloques de bitcoin, y nadie tiene la autoridad o el poder para cambiar esto. Si bien es técnicamente posible que bitcoin sea “hackeado” o controlado, explicaré por qué esto nunca sucederá en un correo electrónico posterior.',
  'WHAT FIXES THIS?.4': 'Bitcoin es una oportunidad para que los no bancarizados almacenen y aumenten su riqueza de forma segura, sin que nadie pueda ser excluido o rechazado, y sin que le importe su puntuación crediticia.',
  'WHAT FIXES THIS?.5': `img:[Dólares fiat contra Bitcoin](${leccion2_1})`,
  'DECENTRALIZATION IS PRIVACY': 'LA DESCENTRALIZACIÓN ES PRIVACIDAD',
  'DECENTRALIZATION IS PRIVACY.1': 'En la banca tradicional, usted está sujeto a aprobaciones basadas en puntuaciones de crédito e identificación gubernamental. Esto hace que todos seamos susceptibles de fraude; si alguien roba tu identidad, puede abrir tarjetas de crédito, generar deudas a tu nombre y destruir tu puntuación crediticia.',
  'DECENTRALIZATION IS PRIVACY.2': 'Con Bitcoin, su participación no depende de su identidad. Cualquiera y todos pueden participar de forma anónima, ya que los nombres y los datos personales no están conectados a las transacciones en la blockchain de Bitcoin.',
  'DECENTRALIZATION IS PRIVACY.3': 'Aunque la mayoría de los intercambios de criptodivisas (lugares donde se puede comprar bitcoin) requieren un proceso de Conozca a su Cliente (KYC) que requiere que verifique su identidad con un selfie y una identificación con foto, una vez que saca su bitcoin del intercambio y lo lleva a sus propias carteras de autocustodia, todas las transacciones a partir de ese momento son seudónimas.',
  'DECENTRALIZATION IS PRIVACY.4': 'Más adelante en esta serie, te mostraré cómo puedes asegurar tu bitcoin sacándolo de los intercambios e introduciéndolo en tus propias soluciones de autocustodia.',
  'DECENTRALIZATION IS PRIVACY.5': 'En última instancia, nadie controla Bitcoin desde arriba, pero Bitcoin le permite ser su propio banco y controlar totalmente su propia riqueza sin la supervisión de nadie más.',
 
  'What Is Money?': '¿Qué es el dinero?',
  '(3:intro)': '',
  '(3:intro).1': 'Pero, ¿qué es el dinero?',
  '(3:intro).2': 'Definido formalmente, el dinero es algo ampliamente aceptado para comprar bienes y servicios o pagar deudas e impuestos. Podría pensarse que se limita a billetes de papel y monedas acuñadas, pero la realidad es que cualquier cosa puede ser dinero, siempre que cumpla estos casos de uso fundamentales: una unidad de cuenta, un medio de intercambio y un depósito de valor.',
  '(3:intro).3': 'Si echamos la vista atrás en la historia, los objetos con los que nuestros antiguos antepasados comerciaban y hacían trueques se consideraban sus formas de dinero. Esto incluía desde conchas y animales hasta plata y oro. En los tiempos modernos, vemos un reflejo de esto en lugares donde el dinero público tiene poco valor, como en las prisiones, donde los cigarrillos y el ramen instantáneo son unidades de cuenta bien conocidas.',
  '(3:intro).4': 'Desde el siglo XIV hasta el siglo XX, las conchas de cauri se utilizaron como moneda de trueque y comercio en África y Asia. Duraderas, divisibles, identificables y escasas, las cauri eran la moneda natural perfecta antes de la era de las monedas. Otros objetos, como las cuentas de vidrio, las piedras y la sal, también se utilizaban en diferentes culturas. Imagínese: hoy puede estar saltando piedras en el océano que antes se consideraban como los dólares de su cuenta bancaria. Todas esas monedas antiguas carecen hoy de valor porque acabaron siendo víctimas del asesino del valor: la hiperinflación.',
  '(3:intro).5': `img:[Dinero duro contra dinero fácil](${leccion3_1})`,
  'LEARNING FROM THE RAI STONES ON YAP ISLAND': 'APRENDIENDO DE LAS PIEDRAS RAI DE LA ISLA DE YAP',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.1': 'Uno de los sistemas monetarios antiguos más interesantes era el uso de piedras Rai en la isla de Yap (parte de Micronesia).',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.2': 'Los yapeses utilizaban como moneda piedras grandes y pesadas -de hasta 3 metros de diámetro- con un agujero en el centro, debido a su rareza y dificultad para conseguirlas en las islas vecinas. Para enviar las piedras rai a Yap mediante balsas y canoas, a menudo se necesitaban cientos de personas, lo que significaba que era casi imposible que alguien inflara rápidamente el suministro.',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.3': 'Durante siglos, las piedras Rai se utilizaron como moneda sólida. Colocadas en un lugar central al que todo el mundo tenía acceso, las piedras Rai sólo se intercambiaban en reconocimiento de propiedad y no de posesión (ya que era imposible llevarlas encima).',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.4': `img:[Piedras de Rai en la isla de Yap](${leccion3_2})`,
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.5': 'Este sistema monetario funcionó bien durante siglos. Pero en 1871, un estadounidense de origen irlandés llamado David O’Keefe llegó a la costa y vio una gran oportunidad de negocio en la producción de aceite de coco obtenido de los abundantes cocos de la isla. Se dio cuenta de que a los yapeses no les interesaba el dinero extranjero, así que se embarcó rumbo a la cercana isla de Palaos, donde utilizó herramientas modernas y explosivos para hacerse con varias piedras Rai de gran tamaño y llevárselas a Yap.',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.6': 'Sin embargo, el valor de las piedras Rai se calculaba basándose en una compleja fórmula de tamaño, historia, calidad y número de vidas perdidas debido a la labor de procurarse estas piedras. En pocas palabras, tenían valor porque eran difíciles de conseguir; las piedras Rai de O’Keefe se obtenían fácilmente, sin tener en cuenta la tradición, por lo que muchos aldeanos no estaban dispuestos a aceptar las piedras como válidas. Un antiguo vestigio de moneda falsa, si se quiere.',
  'LEARNING FROM THE RAI STONES ON YAP ISLAND.7': 'Desgraciadamente, otros yapeses no entendían los conceptos de escasez y moneda sana, por lo que aceptaron de buen grado estas piedras falsas, lo que finalmente condujo a la desaparición del Rai como moneda sana que una vez fue.',
  'MODERN HYPERINFLATION': 'HIPERINFLACIÓN MODERNA',
  'MODERN HYPERINFLATION.1': 'Si echamos un vistazo a los ejemplos modernos de impresión exorbitante de dinero en países como Venezuela y Zimbabue, vemos que se está desarrollando una historia hiperinflacionaria moderna similar. Si queremos aprender de la historia, debemos encontrar soluciones viables a las vulnerabilidades de las que han sido víctimas las monedas anteriores. Pero en lugar de preocuparnos tanto por cosas que no podemos cambiar en fiat, podemos mirar hacia soluciones reales.',
  'MODERN HYPERINFLATION.2': 'Antes, sólo podíamos soñar con cosas imposibles. Ahora tenemos Bitcoin.',
  'MODERN HYPERINFLATION.3': 'Bitcoin es una nueva forma de dinero que resuelve los problemas de escasez y degradación de la moneda. Mañana hablaré del límite fijo de suministro de bitcoin y de por qué nunca perderá su poder adquisitivo como lo han hecho todas las monedas anteriores.',

  'The Magic Number — 21 Million': 'El número mágico: 21 millones',
  '(4:intro)': '',
  '(4:intro).1': 'Imagina la banca central como este tipo:',
  '(4:intro).2': `img:[El hombre de Monopolio](${leccion4_1})`,
  '(4:intro).3': 'El Hombre del Monopoly representa al banco central.',
  '(4:intro).4': 'En las primeras pasadas por el tablero del Monopoly, tienes la oportunidad de comprar bienes inmuebles y otros productos básicos. El mercado está abierto, los precios son asequibles y la competencia es escasa. A veces, tienes mala suerte y debes pagar una multa sorpresa o un impuesto elevado. Pero así es la vida.',
  '(4:intro).5': 'Te permite cobrar 200 dólares cuando pasas GO, sólo porque es un buen tipo. Tal vez ahora hayas repuesto parte de tus ahorros, así que vuelves al tablero y das otra vuelta.',
  '(4:intro).6': 'Después de unas cuantas rondas de propiedades compradas, alquileres pagados a los propietarios y recaudación de dinero gratis en GO, empieza a asaltarte cierta terrible fatalidad: rezas para no caer en el dúplex de alguien, o peor aún, en su hotel. Con cada pasada por el tablero, sigues cobrando tus 200 dólares. No es por aguarte la fiesta, pero 200 dólares no significan mucho cuando tus ahorros se han esfumado y el alquiler parece duplicarse en cada turno.',
  '(4:intro).7': 'Aunque se trata de una versión exagerada del juego de la vida, los principios básicos se mantienen: Los que han invertido en activos (como bienes inmuebles o acciones) ven cómo su patrimonio neto se revaloriza; los que tienen deudas y pocos o ningún activo siguen viendo cómo sus ahorros se esfuman. Su poder adquisitivo se debilita, las materias primas y los activos se encarecen y no hay limosna que pueda salvarles.',
  '(4:intro).8': 'De hecho, el “dinero gratis” vuelve para morderles aún más fuerte en la siguiente ronda. Mientras que los ricos ven cómo se inflan los precios de sus activos porque se introduce más dinero en el juego, los pobres ven cómo se degradan sus ahorros porque las necesidades que antes podían permitirse (como el alquiler) se vuelven insoportablemente caras.',
  '(4:intro).9': 'Este es el resultado de la inflación: cuando el gobierno imprime dinero a capricho para alimentar la economía. Pero al final, los ricos sólo se hacen más ricos y el precio de los bienes se encarece, atrapando así a la gente en un círculo de pobreza, aunque cobren 200 dólares en GO.',
  '(4:intro).10': `img:[El moneda del Monopolio](${leccion4_2})`,
  '(4:intro).11': 'El gobierno puede inflar la oferta monetaria cuando quiera. Quieren que pienses que todo está bajo control - y lo está, literalmente. Ahí está el problema: Te apuñalarán por la espalda mientras te dan la mano.',
  '(4:intro).12': 'Sin embargo, con Bitcoin nadie tiene el control. La oferta de Bitcoin está limitada a 21 millones, mientras que los dólares fiduciarios pueden imprimirse ilimitadamente.',
  '(4:intro).13': 'El resultado del límite de oferta de bitcoin es la escasez. A medida que aumente la demanda de bitcoin, también lo hará su precio y, por tanto, su poder adquisitivo.',
  'THE MAGIC NUMBER': 'EL NÚMERO MÁGICO',
  'THE MAGIC NUMBER.1': 'Nadie sabe de dónde procede el tope de 21 millones de bitcoins; es probable que se trate de una cifra elegida arbitrariamente. Sin embargo, lo importante es que este número no puede cambiarse nunca. Está integrado en el código y, para cambiar el software y aumentar el límite de suministro, todo el mundo tendría que estar de acuerdo. Sin embargo, la gente no quiere que el valor de su bitcoin disminuya, por lo que no hay manera de llegar al consenso necesario para aumentar este número.',
  'THE MAGIC NUMBER.2': 'Obviamente, hay más de 21 millones de personas en el mundo; esto significa que no todo el mundo puede poseer un bitcoin entero. De hecho, actualmente hay el doble de millonarios que de bitcoins en el mundo; esto debería dar una idea del valor futuro del bitcoin a medida que siga creciendo su adopción.',
  'THE MAGIC NUMBER.3': 'Aunque la oferta de bitcoins está limitada, cada bitcoin puede dividirse en “satoshis”, de forma parecida a como los dólares pueden dividirse en céntimos. Cada bitcoin contiene 100 millones de satoshis, por lo que hay 2,1 cuatrillones de satoshis en total.',
  'SUPPLY AND DEMAND': 'LA OFERTA Y LA DEMANDA',
  'SUPPLY AND DEMAND.1': 'En la actualidad, aún no están disponibles los 21 millones de bitcoins, algo que explicaré más adelante cuando hable de cómo se extraen los nuevos bitcoins. Pero a medida que aumente lentamente la oferta hasta que no haya más bitcoins nuevos disponibles, la demanda y la adopción determinarán el precio del bitcoin.',
  'SUPPLY AND DEMAND.2': 'En tu clase de economía básica del instituto, probablemente aprendiste un concepto microeconómico llamado curva de oferta y demanda. Aunque ningún modelo económico es perfectamente representativo de un escenario del mundo real, la curva de bitcoin es un poco especial.',
  'SUPPLY AND DEMAND.3': 'Veamos este gráfico de oferta y demanda en el que se comparan el salmón y el bitcoin:',
  'SUPPLY AND DEMAND.4': `img:[La oferta y la demanda](${leccion4_3})`,
  'SUPPLY AND DEMAND.5': 'Mientras que la oferta de salmón no es necesariamente fija porque podemos criar peces y abastecernos de peces de diferentes zonas del mundo, la oferta de bitcoin está limitada estrictamente a 21 millones, es lo que llamamos una oferta perfectamente inelástica.',
  'SUPPLY AND DEMAND.6': 'Como resultado, esto significa que sólo los cambios en la demanda afectan al precio; a medida que la demanda sigue aumentando con la adopción institucional y países como El Salvador declaran el bitcoin de curso legal, sólo hay un lugar al que se dirige el precio del bitcoin: la luna. A corto plazo, sin embargo, la demanda puede bajar y el precio caerá con ella.',
  'SUPPLY AND DEMAND.7': 'Aunque todavía estamos lejos de utilizar bitcoin de la misma forma que utilizamos dólares para comprar bienes, lo que sí podemos hacer es mantener nuestra riqueza en bitcoin. Mientras que el poder adquisitivo del dólar se debilita debido a una oferta siempre inflada, la oferta de bitcoin permanece inmutable, por lo que el poder adquisitivo de bitcoin debería seguir aumentando con el tiempo... para siempre.',

  'PoW — Proof Of Work Explained': 'Explicación de la prueba de trabajo',
  '(5:intro)': '',
  '(5:intro).1': 'Es posible que hayas oído hablar de este término en el espacio bitcoin: PoW [por “Proof of Work” en inglés, que significa “la prueba de trabajo”].',
  '(5:intro).2': 'PoW me recuerda a este viejo cómic de superhéroes:',
  '(5:intro).3': `img:[La prueba de trabajo](${leccion5_1})`,
  '(5:intro).4': 'Y además es un buen símbolo. La prueba de trabajo es realmente como un superhéroe, ya que permite que Bitcoin funcione sin un controlador centralizado como el gobierno. Es la llave que abre la solución auto-soberana que hemos estado buscando: un algoritmo informático que podría arreglar nuestro sistema financiero.',
  '(5:intro).5': 'Esto es lo que significa.',
  'WORKING SELFISHLY FOR THE GREATER GOOD': 'TRABAJAR EGOÍSTAMENTE POR UN BIEN MAYOR',
  'WORKING SELFISHLY FOR THE GREATER GOOD.1': 'Proof of Work es un algoritmo de consenso que requiere que sus participantes -los mineros de Bitcoin- gasten energía y potencia de cálculo para bloquear lotes de nuevas transacciones. A cambio, se les recompensa con bitcoins si son los primeros en calcular con éxito un difícil número de serie hexadecimal de 64 caracteres (un hash) que identifica el historial de transacciones anteriores, las nuevas transacciones y su propio ID como minero ganador.',
  'WORKING SELFISHLY FOR THE GREATER GOOD.2': 'En otras palabras, los mineros quieren esta recompensa de bitcoins para ellos, por lo que trabajarán sin descanso para intentar crear una solución ganadora. El ganador envía entonces su solución y la lista de transacciones que incluye a la blockchain, asegurando así esas transacciones públicamente, para siempre. Aproximadamente cada diez minutos, este proceso se repite para ayudar a descentralizar, asegurar y confirmar todas las transacciones de la cadena de bloques, al tiempo que se recompensa a los mineros por sus pruebas de trabajo.',
  'WORKING SELFISHLY FOR THE GREATER GOOD.3': `img:[La minería de diamantes es como la minería de bitcoins](${leccion5_2})`,
  'WORKING SELFISHLY FOR THE GREATER GOOD.4': 'Si todo esto te ha parecido poco, aquí tienes una analogía que te ayudará a entenderlo mejor.',
  'LOOKING FOR DIAMONDS': 'EN BUSCA DE DIAMANTES',
  'LOOKING FOR DIAMONDS.1': 'Los diamantes, como el bitcoin, son raros. No se pueden falsificar, son difíciles de encontrar y todo el mundo quiere uno. Por el bien de esta analogía, vamos a fingir que los diamantes cultivados en laboratorio no existen.',
  'LOOKING FOR DIAMONDS.2': 'Imagínese que un cliente quiere un diamante de un tamaño determinado como mínimo. Si usted encuentra un diamante que se ajuste al requisito, entonces le pagan. Cuanto más grandes son los diamantes, más difíciles son de encontrar. Ahora bien, como los diamantes son tan raros, hay que dedicar tiempo a reunir piedras y esforzarse por romperlas. Es cuestión de suerte: algunas piedras se tiran, mientras que con otras se puede hacer un anillo de diamantes.',
  'LOOKING FOR DIAMONDS.3': 'En ocasiones, hay suerte y el primer mineral que se rompe cumple el requisito de tamaño. Otras veces, tardas mucho más en encontrar sólo un diamante pequeño. Pero incluso si encuentras varios diamantes pequeños, al cliente no le importa si ninguno de ellos tiene el tamaño adecuado. Este es un punto importante para la minería de bitcoins: el trabajo no se acumula. Los resultados se basan en gran medida en la suerte. Pero, cuanto más trabajes, más suerte puedes tener.',
  'MINING POOLS': 'PISCINAS MINERAS',
  'MINING POOLS.1': 'Algunos mineros se dan cuenta de que todo este trabajo podría hacerse mejor en grupo, así que colaboran y forman grupos. Deciden que si alguien del grupo encuentra un diamante lo suficientemente grande para el cliente, entonces todo el grupo recibe un pago en función de la cantidad de trabajo que hayan realizado. Miden el trabajo realizado pesando individualmente los pequeños diamantes que cada uno ha podido encontrar con respecto al total.',
  'MINING POOLS.2': 'En Bitcoin, los mineros se unen para formar pools de minería de forma similar. Las recompensas de Bitcoin se distribuyen dentro de un pool de minería en función de la cantidad de trabajo que hayan realizado los mineros.',
  'ADJUSTING FOR DIFFICULTY': 'AJUSTE OF LA DIFICULTAD',
  'ADJUSTING FOR DIFFICULTY.1': 'A medida que más gente se da cuenta de que se puede ganar dinero buscando diamantes, aumenta el número total de participantes, lo que incrementa la probabilidad de que alguien encuentre rápidamente un diamante de la talla adecuada.',
  'ADJUSTING FOR DIFFICULTY.2': 'Supongamos que cada dos semanas el cliente toma nota del tiempo que tarda en encontrar un diamante de un tamaño determinado. Si hay más gente trabajando y se tarda menos tiempo de media en encontrar los diamantes, entonces los requisitos de tamaño de los diamantes aumentan durante las dos semanas siguientes. Como los diamantes más grandes son más raros, es mucho más difícil encontrar un diamante si los requisitos de tamaño aumentan, y viceversa.',
  'ADJUSTING FOR DIFFICULTY.3': `img:[Ajuste de la dificultad](${leccion5_3})`,
  'ADJUSTING FOR DIFFICULTY.4': 'El protocolo Bitcoin tiene un ajuste de dificultad incorporado. Cada 2.016 bloques (unas dos semanas), la dificultad para minar bitcoin se ajusta a medida que más mineros se conectan o desconectan. Si hay más potencia de cálculo trabajando para resolver el hash, será más difícil encontrar una solución ganadora. Si los mineros se desconectan por alguna razón (como después de que China prohibiera minar), entonces es más fácil minar bitcoin para los mineros que siguen conectados.',
  'ADJUSTING FOR DIFFICULTY.5': 'El objetivo es alcanzar el equilibrio y emitir nuevos bitcoins a un ritmo constante: una media de diez minutos por nuevo bloque. Puedes seguir cómo se ajusta la dificultad aquí: https://btc.com/stats/diff',

  'The Halving — Bitcoin’s Mining Schedule': 'La reducción a la mitad - El calendario minero de Bitcoin',
  '(6:intro)': '',
  '(6:intro).1': 'Ayer repasamos cómo funciona Proof of Work para confirmar transacciones y crear nuevos bitcoins. Hoy describiré de forma más general cómo es la minería y cómo se distribuyen los nuevos bitcoins a lo largo del tiempo.',
  'WHAT DOES MINING LOOK LIKE?': '¿QUÉ ASPECTO TIENE LA MINERÍA?',
  'WHAT DOES MINING LOOK LIKE?.1': 'La minería en la era industrial frente a la minería en la era digital:',
  'WHAT DOES MINING LOOK LIKE?.2': `img:[Arruinado vs así citado](${leccion6_1})`,
  'WHAT DOES MINING LOOK LIKE?.3': 'La minería de Bitcoin no se parece a ningún otro tipo de minería. Es fácil de instalar, funciona 24/7 y no viola los derechos humanos ni las leyes laborales.',
  'WHAT DOES MINING LOOK LIKE?.4': 'Funciona así. Básicamente, se instala un grupo de ordenadores especializados (ASIC: circuitos integrados de aplicación específica) para minar bitcoin en algún lugar donde la electricidad sea barata (ya que los mineros necesitan mucha potencia de cálculo). Como se dijo ayer, los mineros pueden trabajar juntos en pools para repartirse los beneficios y aumentar sus probabilidades de ganar un nuevo bloque.',
  'WHAT DOES MINING LOOK LIKE?.5': 'Con cada nuevo bloque de Bitcoin que se mina, se registran y confirman lotes de transacciones en la cadena de bloques. Al confirmar las transacciones y calcular un hash ganador, los mineros son recompensados con bitcoins recién acuñados. Sin embargo, esta recompensa no durará para siempre, ya que la oferta de bitcoins está limitada.',
  'THE INCOMING SUPPLY OF NEW BITCOIN': 'LA ENTRADA DE NUEVOS BITCOINS',
  'THE INCOMING SUPPLY OF NEW BITCOIN.1': 'La oferta total de Bitcoin está limitada a 21 millones. Pero eso no significa que los 21 millones estén disponibles.',
  'THE INCOMING SUPPLY OF NEW BITCOIN.2': 'Los mineros seguirán recibiendo nuevos bitcoins hasta que se extraiga el último bitcoin, lo que se calcula que ocurrirá en el año 2140. Cuando se agoten los 21 millones de bitcoins, habrá que incentivar a los mineros para que sigan invirtiendo el tiempo, la energía y los gastos necesarios para ayudar a confirmar las transacciones.',
  'THE INCOMING SUPPLY OF NEW BITCOIN.3': 'A medida que la red crece, se espera que las tasas que pagan los usuarios a cambio de su prueba de trabajo continuada aumenten lo suficiente como para que la minería siga siendo rentable. Más adelante hablaré de las preocupaciones que suscitan las elevadas tasas de transacción de los usuarios.',
  'THE INCOMING SUPPLY OF NEW BITCOIN.4': 'En 2021 ya se habrán acuñado unos 18,5 millones de bitcoins. Durante el próximo siglo, a medida que se extraigan los últimos bitcoins restantes, las recompensas de los bloques de bitcoins se irán reduciendo lentamente según un calendario establecido. Esto se llama “la (reducción) a la mitad“.',
  'THE HALVING: A FOUR YEAR CYCLE': 'LA REDUCCIÓN A LA MITAD: UN CICLO DE CUATRO AÑOS',
  'THE HALVING: A FOUR YEAR CYCLE.1': 'En la génesis de la red Bitcoin, se minaban 50 bitcoins en cada bloque.',
  'THE HALVING: A FOUR YEAR CYCLE.2': 'Cada 210.000 bloques -aproximadamente cada cuatro años-, el número de bitcoins nuevos extraídos por bloque se reduce a la mitad. A partir de 2021, sólo se minarán 6,25 bitcoins nuevos en cada bloque. En 2024, la recompensa por bloque se reducirá a 3,125 bitcoins.',
  'THE HALVING: A FOUR YEAR CYCLE.3': 'Este es el calendario de reducción a la mitad de Bitcoin:',
  'THE HALVING: A FOUR YEAR CYCLE.4': `img:[La a la Mitad](${leccion6_2})`,
  'THE HALVING: A FOUR YEAR CYCLE.5': 'Cuando la oferta de nuevos bitcoins introducidos se reduce a la mitad, su tasa de inflación también se reduce efectivamente a la mitad. Esto nos devuelve a nuestros conceptos básicos de oferta y demanda.',
  'THE HALVING: A FOUR YEAR CYCLE.6': 'Con el tiempo, a medida que más personas conocen la red Bitcoin y empiezan a participar en ella, la demanda aumenta. Al mismo tiempo, el crecimiento de la oferta se reduce debido a las divisiones. Como resultado, el precio del bitcoin sube cada vez más en cada ciclo de cuatro años.',
  'THE HALVING: A FOUR YEAR CYCLE.7': 'Otra razón para que los mineros reciban menos recompensas de bitcoins en cada ciclo de reducción a la mitad es la previsión del crecimiento de la red. A medida que más usuarios entran en la red Bitcoin, más transacciones tienen lugar. Cada bloque sólo puede contener un número determinado de transacciones, por lo que los usuarios deben esencialmente “pujar” por un lugar para que su transacción sea confirmada.',
  'THE HALVING: A FOUR YEAR CYCLE.8': 'Para los mineros, esto significa que reciben elevadas comisiones de los usuarios como incentivo para incluir su transacción en el siguiente bloque.',
  'THE HALVING: A FOUR YEAR CYCLE.9': 'Para los usuarios, esto significa comisiones cada vez más altas o tiempos de transacción más lentos.',
  'THE HALVING: A FOUR YEAR CYCLE.10': 'Se trata de una cuestión sobre la que se ha reflexionado mucho, y los nuevos avances están aún en sus primeras fases. Pero existen soluciones viables, como la Lightning Network. A medida que siga aprendiendo sobre Bitcoin en las próximas semanas, profundizaré en las soluciones para escalar las transacciones y en cómo podemos seguir desarrollando la red.',

  'Bitcoin Full Nodes — “Verify, Don’t Trust”': 'Nodos completos de Bitcoin - “Verifica, no confíes”',
  '(7:intro)': '',
  '(7:intro).1': 'Existen tres tipos de nodos: nodos mineros, nodos completos y nodos ligeros. Acabamos de hablar de los nodos de minería (también conocidos como mineros), que confirman las transacciones pendientes en la cadena de bloques y extraen nuevos bitcoins. Los nodos completos y los nodos ligeros son los que validan los nuevos bloques y transacciones enviados por los mineros.',
  '(7:intro).2': 'Los nodos completos de Bitcoin tienen tres funciones principales: mantener una copia de toda la cadena de bloques de Bitcoin, validar transacciones y hacer cumplir las reglas de la red. Los nodos ligeros de Bitcoin hacen lo mismo que los nodos completos, pero no guardan una copia de todo el historial de la cadena de bloques. Nos centraremos principalmente en los nodos completos.',
  '(7:intro).3': `img:[Una comparación de los tipos de nodos](${leccion7_1})`,
  'THE PURPOSE OF NODES IN A NETWORK': 'LA FINALIDAD DE LOS NODOS DE UNA RED',
  'THE PURPOSE OF NODES IN A NETWORK.1': 'Cuantos más nodos estén distribuidos por una red global, más descentralizado estará el sistema. Dado que la red Bitcoin tiene el mayor número de nodos (incluyendo mineros) de cualquier criptodivisa, es la blockchain más segura. Mientras que muchas otras criptomonedas son propensas a hackeos y ataques al blockchain, Bitcoin es virtualmente inmune - y mañana explicaré exactamente por qué.',
  'THE PURPOSE OF NODES IN A NETWORK.2': 'La función de un nodo es comunicarse directamente con los demás nodos de la red, verificando que su historial de transacciones está alineado con el del nodo siguiente. Cuando verifican nuevas transacciones, comprueban que el bitcoin que se está negociando no se ha gastado dos veces y que no se está creando bitcoin de la nada. Si un nodo detecta intentos de transacción maliciosos, junto con los demás nodos de la red, rechazará las transacciones.',
  'THE PURPOSE OF NODES IN A NETWORK.3': 'Las transacciones de Bitcoin se verifican a través de los nodos de forma similar a como se propagan los rumores: Terrible y cruel, un rumor se hace eco a través de la red de cotilleos hasta que todo el mundo en tercer curso sabe lo enamorada que estás de Joshua. Pero a diferencia de los rumores, los nodos completos sólo transmiten las transacciones que ellos mismos han verificado, de acuerdo con las normas que aplican y el historial de blockchain al que hacen referencia.',
  'THE PURPOSE OF NODES IN A NETWORK.4': 'Dado que los nodos ejecutan el software central de Bitcoin, que establece todas las reglas de la red (como el límite de 21 millones), todos los nodos completos ayudan a proteger la red verificando únicamente las transacciones adecuadas a un bloque pendiente, además de verificar los bloques completados emitidos por los mineros. Si por casualidad un nodo acepta una transacción maliciosa en un bloque, los demás nodos de la red rechazarán el bloque por completo.',
  'THE PURPOSE OF NODES IN A NETWORK.5': `img:[Eficaz y práctico](${leccion7_2})`,
  'RUNNING YOUR OWN FULL NODE!': '¡EJECUTANDO TU PROPIO NODO COMPLETO!',
  'RUNNING YOUR OWN FULL NODE!.1': '¿Sabías que puedes ejecutar tu propio nodo? Es muy fácil con instaladores de nodos de código abierto y fáciles de usar como Umbrel. Puedes seguir su guía para obtener instrucciones detalladas. Es barato, bastante sencillo y contribuye a la seguridad de la red Bitcoin.',
  'RUNNING YOUR OWN FULL NODE!.2': 'Aunque Bitcoin es bastante seguro y muchos afirman que ejecutar un nodo extra no hará mucho por mejorar la red, ayuda a tener un exceso de nodos en todo el mundo en el caso de que se produzcan raros eventos de Cisne Negro, como que los nodos bajo ciertas jurisdicciones gubernamentales sean cerrados todos a la vez.',
  'RUNNING YOUR OWN FULL NODE!.3': 'Ejecutar tu propio nodo también te ayuda a eliminar la confianza necesaria para que otros nodos sigan siendo honestos. Aunque la mayoría de ellos siguen siendo verdaderos, al ejecutar transacciones a través de tu propio nodo completo, te estás convirtiendo esencialmente en tu propio banco. Es una característica muy interesante que no es posible con dinero fiduciario, oro o cualquier otra cosa que pueda utilizarse como dinero.',

  'Can Bitcoin Be Hacked?': '¿Se puede piratear Bitcoin?',

  'why:title': '¿Por qué se traslada Bitcoin a Paraguay?',
  'why:blurb': 'Bitcoin y Paraguay son una pareja perfecta. Este breve artículo explica los puntos clave que necesitas saber sobre el traslado de las operaciones mineras de Bitcoin a Paraguay, y qué implicancias tiene para el mundo.',
  'why.1': 'Paraguay es el lugar ideal para la minería de Bitcoin por las siguientes razones:',
  'why.2': 'tag:ol',
  'why.2.1': 'Paraguay tiene 10 veces más electricidad disponible de la que puede utilizar en la actualidad, lo que actualmente supone una pérdida de ingresos. Los mineros de Bitcoin pueden utilizar fácilmente esta energía, proporcionando a cambio ingresos a Paraguay. Las empresas mineras de Bitcoin y Paraguay salen ganando.',
  'why.2.2': 'El tratado entre Paraguay y Brasil se renegociará este año 2023. El potencial de la minería de Bitcoin en Paraguay da a la nación una baza para ayudar a negociar términos mejores y más rentables que en el pasado. Las operaciones de minería de Bitcoin pueden reubicarse y establecerse muy rápidamente y los beneficios son inmediatos.',
  'why.2.3': 'La producción de energía de Paraguay es en un 99,9% hidroeléctrica limpia. Esto significa que las instalaciones de minería de Bitcoin que se trasladen a Paraguay desde redes eléctricas menos respetuosas con el medio ambiente estarán realizando un cambio positivo y cuantificable hacia un mundo mejor.',
  'why.3': 'Los tres puntos anteriores son el beneficio natural de las empresas que buscan obtener beneficios como parte de sus operaciones comerciales. Los precios comparativamente baratos de la energía en Paraguay hacen que gane la industria minera de Bitcoin, mientras que el consumo interno de electricidad hace que gane el país.',
  'why.4': 'A medida que los factores competitivos y circunstanciales ejerzan más y más presión sobre la minería de Bitcoin, más y más instalaciones mineras querrán trasladarse a Paraguay, especialmente como resultado de la "reducción a la mitad" de Bitcoin que se producirá en la primavera de 2024. Después de la "reducción a la mitad", sólo las empresas mineras mejor posicionadas y más rentables serán capaces de sobrevivir, cosechando una mayor parte de las recompensas mineras como resultado. En este negocio altamente competitivo, Paraguay puede convertirse en la nación líder en minería de Bitcoin en el mundo.',
  'why.5': 'Para un pequeño país sin salida al mar como Paraguay, se trata de una oportunidad de crecimiento de gran valor. El interés de extranjeros de todas partes del mundo generará inevitablemente algo más que ingresos energéticos. También habrá importación de hardware y tecnología, ingresos turísticos, contribuciones a la economía general y más desarrollo tecnológico e innovación.',
  'why.6': 'Fácilmente se pasa por alto el hecho de que el Bitcoin recién minado obtenido como ingresos de las operaciones de minería generalmente pertenece a los propietarios del hardware de minería, y estas personas y empresas estarán adquiriendo un interés personal en Paraguay. Sus ingresos -el dinero del futuro (es decir, Bitcoin)- tratarán de filtrarse en la economía de Paraguay, abriendo oportunidades para que la población en general gane Bitcoin junto con los guaraníes. Para la nación, esto a su vez se convertirá en otro canal de ingresos del IVA que fluirá a través de las empresas paraguayas.',
  'why.7': 'Al igual que la energía producida en Itaipú es energía limpia, el Bitcoin producido en las operaciones mineras es dinero limpio sin historial negativo. Si Paraguay actúa con sensatez en este año decisivo, el país puede conseguir desprenderse de los estigmas del pasado y asegurarse un futuro de prosperidad en el que todas las partes salgan ganando: el gobierno del país, sus ciudadanos, los extranjeros y el planeta en su conjunto. Tan abundante como el caudaloso Iguazú proporciona hoy luz a los hogares paraguayos, les proporcionará mañana seguridad financiera.',

  'status': 'estado',
  'platforms': 'plataformas compatibles',
  'software-platform:android-native': 'Android nativo',
  'software-platform:web-browser': 'Web - todos los dispositivos',
  'software-platform:android-native-discontinued': 'Android nativo - descatalogado',
  'software-platform:pwa': 'Aplicación web progresiva (PWA) - todos los dispositivos',
  'key-features': 'características principales',
  'specifications': 'especificaciones técnicas',
  'license': 'código fuente y licencias',
  'security': 'consideraciones de seguridad',

  'ng:title': 'Nuevo Guaraní',
  'ng:blurb': 'La experiencia del punto de venta paraguayo se amplía con el dinero electrónico entre pares. Con estas aplicaciones, ¡comprar y vender vuelve a ser divertido!',
  'ng:subtitle': 'Aplicación de punto de venta para la gente',
  'ng:content': '',
  'ng:status': 'La aplicación está en fase de desarrollo y se está probando sobre el terreno.',
  'ng:feature1': 'Funciona tanto con efectivo en papel como con efectivo electrónico (¡en la misma venta!)',
  'ng:feature2': 'Tan fácil de usar como una calculadora',
  'ng:feature3': 'Selección visual de productos',
  'ng:feature4': 'Compatibilidad con códigos de barras',
  'ng:feature5': 'Integración con otros programas',
  'ng:spec1': 'Para las funciones de efectivo electrónico, bitcoin es la moneda y satoshi la unidad, con pagos a través de la red relámpago (Lightning).',
  'ng:spec2': 'El monedero electrónico está gestionado por LNbits (configurable; autoalojado o custodiado)',
  'ng:spec3': 'Los precios de los productos y las imágenes pueden obtenerse en un puesto de NostrMarket (a través de LNbits).',
  'ng:licensing': 'Este software es de código abierto bajo la licencia ',
  'ng:licensing2': '.',
  'ng:security': 'Todos los datos de la aplicación se almacenan en una única base de datos en el dispositivo local (IndexedDB). Los datos de configuración sensibles, como las claves de acceso total o de sólo lectura del monedero, no se vuelven a mostrar. No hay protección por contraseña. Se aconseja a los usuarios que transfieran regularmente fondos a un monedero más seguro para minimizar el riesgo en caso de que el dispositivo se vea comprometido.',

  'ng-pos:title': 'Nuevo Guaraní PoS',
  'ng-pos:blurb': 'La experiencia del punto de venta paraguayo se amplía con el dinero electrónico entre pares. Con estas aplicaciones, ¡comprar y vender vuelve a ser divertido!',
  'ng-pos:subtitle': 'Aplicación de punto de venta para la gente',
  'ng-pos:content': '',
  'ng-pos:status': 'La aplicación está en fase de desarrollo y se está probando sobre el terreno.',
  'ng-pos:feature1': 'Funciona tanto con efectivo en papel como con efectivo electrónico (¡en la misma venta!)',
  'ng-pos:feature2': 'Tan fácil de usar como una calculadora',
  'ng-pos:feature3': 'Selección visual de productos',
  'ng-pos:feature4': 'Compatibilidad con códigos de barras',
  'ng-pos:feature5': 'Integración con otros programas',
  'ng-pos:spec1': 'Para las funciones de efectivo electrónico, bitcoin es la moneda y satoshi la unidad, con pagos a través de la red relámpago (Lightning).',
  'ng-pos:spec2': 'El monedero electrónico está gestionado por LNbits (configurable; autoalojado o custodiado)',
  'ng-pos:spec3': 'Los precios de los productos y las imágenes pueden obtenerse en un puesto de NostrMarket (a través de LNbits).',
  'ng-pos:licensing': 'Este software es de código abierto bajo la licencia ',
  'ng-pos:licensing2': '.',
  'ng-pos:security': 'Todos los datos de la aplicación se almacenan en una única base de datos en el dispositivo local (IndexedDB). Los datos de configuración sensibles, como las claves de acceso total o de sólo lectura del monedero, no se vuelven a mostrar. No hay protección por contraseña. Se aconseja a los usuarios que transfieran regularmente fondos a un monedero más seguro para minimizar el riesgo en caso de que el dispositivo se vea comprometido.',

  'ng-wallet:title': 'Nuevo Guaraní Monedero',
  'ng-wallet:blurb': `Intercambio de dinero entre iguales de forma sencilla, en la moneda y el idioma locales.`,
  'ng-wallet:subtitle': 'Una aplicación de monedero para la nación.',
  'ng-wallet:content': '',
  'ng-wallet:status': 'Versión beta de la PWA.',
  'ng-wallet:download-apk': `¡Descargar APK ahora!`,
  'ng-wallet:feature1': 'Interfaz de usuario minimalista para un uso sencillo, rápido y cómodo.',
  'ng-wallet:feature2': 'Alice inicia un envío o una recepción e introduce el importe para crear un código QR que Bob debe escanear y confirmar.',
  'ng-wallet:feature3': 'Todos los importes y saldos se introducen y visualizan en la moneda guaraní (₲, PYG).',
  'ng-wallet:feature4': 'El saldo de la cartera está oculto pero fácilmente visible.',
  'ng-wallet:feature5': 'Protección mediante PIN.',
  'ng-wallet:feature6': 'Funciona perfectamente con otras aplicaciones.',
  'ng-wallet:feature7': 'Modo luz y modo noche.',
  'ng-wallet:feature8': 'Cómodo enlace (código QR) para compartir la aplicación.',
  'ng-wallet:spec1': 'Los pagos se envían a través de la red Lightning.',
  'ng-wallet:spec2': 'Desarrollado por LNbits (actualmente sólo custodia; la opción de configuración para monederos autoalojados estará disponible en breve)',
  'ng-wallet:licensing': 'Este software es de código abierto bajo la licencia ',
  'ng-wallet:licensing2': '.',
  'ng-wallet:security1': 'Las claves nunca se muestran.',
  'ng-wallet:security2': 'La protección mediante PIN protege la cartera de usos no autorizados.',
  'ng-wallet:privacy1': 'La aplicación no transmite más datos personales que los necesarios para enviar y recibir.',
  'ng-wallet:privacy2': 'El depositario recibe los detalles de la transacción, pero no información personal identificable.',


  'products': 'productos',

  'bitcoin-node:title': 'Nodo Bitcoin',
  'bitcoin-node:blurb': 'Su nodo Bitcoin es su puerta de enlace privada para “No confíe, verifique” sus transacciones Bitcoin, direcciones de monedero, saldos de cuenta, tasas pagadas o cualquier otro dato que esté en la blockchain Bitcoin. También es su terminal “sin permisos” para iniciar cualquier transacción en la red Bitcoin.',
  'bitcoin-node:subtitle': '',
  'bitcoin-node:affiliate': '',
  'bitcoin-node:content': '',

  'ant-s19j:title': 'Antminer S19J Pro+ 120TH Bitcoin Minero',
  'ant-s19j:blurb': 'Comprar un minero y alojarlo en Paraguay es la mejor manera de invertir en Bitcoin, porque te paga con bitcoin probadamente virgen directamente a tu propio monedero a los mejores precios de electricidad.',
  'ant-s19j:subtitle': '',
  'ant-s19j:affiliate': 'La compra a través del enlace de afiliado de Satoshi le da derecho a las ventajas de afiliación durante la vida útil del producto.',
  'ant-s19j:content': '',

  'atm-2way:title': 'BitPoint Ultra doble sentido Bitcoin cajero automático',
  'atm-2way:blurb': 'Este producto proporciona un intercambio bidireccional entre bitcoin y papel moneda fiduciaria. Puede ser una excelente forma de apoyar a sus clientes y promover la economía circular.',
  'atm-2way:subtitle': '',
  'ant-s19j:affiliate': 'La compra a través del enlace de afiliado de Satoshi le da derecho a las ventajas de afiliación durante la vida útil del producto.',
  'atm-2way:content': '',

  'techcons:title': 'consulta técnica',
  'techcons:subtitle': 'Contamos con una amplia gama de conocimientos técnicos y experiencia, y probablemente podamos ofrecerle orientación sobre qué hacer y qué evitar a medida que avanza en su misión.',
  'tech-consult:desc1a': 'Satoshi ofrece un servicio técnico integral para ayudarle en todos los aspectos de Bitcoin y las tecnologías relacionadas con Bitcoin. Para empezar, reserve una consulta hoy mismo visitando nuestro puesto en el ',
  'tech-consult:desc1b': 'mercado comunitario',
  'tech-consult:desc1c': ' para realizar un pedido en línea para su primera consulta. Por favor, incluya suficiente información de contacto y requisitos de programación. Si por cualquier motivo no podemos ponernos en contacto con usted o atender su solicitud de consulta, le devolveremos el importe de su reserva. Una vez que haya reservado con éxito su consulta, nos pondremos en contacto con usted para confirmar la programación antes de la consulta real.',

  'webdev:title': 'desarrollo web',
  'webdev:blurb': 'Crear un escaparate virtual le proporciona una ventaja competitiva al permitir que los clientes le conozcan y pidan productos para su entrega sin salir de casa.',
  'webdev-link-kludge': '/contacto?n=1&m=Hola%2C%20me%20gustar%C3%ADa%20hacer%20una%20pregunta%20sobre%20desarrollo%20web.%0A...%0A',

  'compfix:title': 'reparación informática',
  'compfix:blurb': 'Servicios de limpieza y reparación de equipos y programas informáticos.',
  'compfix-link-kludge': '/contacto?n=1&m=Hola%2C%20me%20gustar%C3%ADa%20hacer%20una%20pregunta%20sobre%20reparaci%C3%B3n%20inform%C3%A1tica.%0A...%0A',

  'market:title': 'Mercado comunitario en línea',
  'market:subtitle': 'El mercado en línea es un lugar donde puede encontrar productores y proveedores de servicios locales y hacer compras en línea para su entrega en zonas seleccionadas.',
  '(default)': '',
  '(default).1': `Enlaces rápidos: {link:https://mercado.satoshidnc.com^¡Compre en línea ahora!} | {link:https://btcmap.org/map#12/-25.23680/-57.43610^Ver mapa de la comunidad}`,
  'What makes this a great platform': 'Por qué es una gran plataforma',
  'What makes this a great platform.1': 'Entre iguales (“peer-to-peer”). Los vendedores y los clientes pueden interactuar directamente, si lo desean, y los vendedores pueden llevar sus relaciones con los clientes allá donde vayan.',
  'What makes this a great platform.2': 'No son exclusivos. Satoshi sirve para conectar a clientes con vendedores, pero los protocolos están abiertos para que ambas partes alojen o utilicen también otros lugares de mercado.',
  'What makes this a great platform.3': 'Respeto a la privacidad. Las comunicaciones están protegidas por tus claves privadas y sólo compartes la información que deseas compartir.',
  'What makes this a great platform.4': 'Compatible con Lightning. Paga de forma instantánea y segura con cualquier monedero compatible con Lightning.',
  'How does Satoshi profit?': '¿Cómo se beneficia Satoshi?',
  'How does Satoshi profit?.1': 'Satoshi gana proporcionando a los vendedores cursos de formación y/o el esfuerzo inicial para poner los productos en línea.',
  'How does Satoshi profit?.2': 'El objetivo es fomentar una próspera red entre iguales que no dependa de Satoshi como único punto de fallo.',
  'How does Satoshi profit?.3': '(Si desea albergar un mercado por redundancia y/o variedad, Satoshi puede orientarle).',
  'How it works-customers and clients': 'Cómo funciona el mercado en línea para clientes y consumidores',
  'How it works-customers and clients.1': '1: Comprar. 2: Salida. 3: Seguimiento de cualquier comunicación adicional necesaria en relación con la entrega o la recogida.',
  'How it works-customers and clients.2': `btn:[¡Empiece a comprar ya!](https://mercado.satoshidnc.com)`,
  'How it works-businesses': 'Cómo funciona el mercado en línea para las empresas',
  'How it works-businesses.1': 'Los particulares o las empresas pueden albergar su propio puesto en el mercado, o pueden utilizar los servicios de Satoshi en diversos grados para gestionar sus pedidos en línea.',
  'How it works-businesses.2': 'La opción ideal para el empresario experto en tecnología es alojar su propio monedero y puesto de productos y simplemente solicitar aparecer en la página del mercado de Satoshi de forma gratuita.',
  'How it works-businesses.3': 'La siguiente mejor opción para el empresario industrius es utilizar Satoshi para alojar su puesto de productos por un coste insignificante, y crear la lista de productos y gestionar los pedidos diariamente él mismo.',
  'How it works-businesses.4': 'Los vídeos que se muestran a continuación serán de utilidad para aquellos que deseen seguir una de esas dos primeras opciones. Por último, la opción más fácil, manos libres para los propietarios ocupados es solicitar que Satoshi manejar el procesamiento de las ventas en línea sobre una base de comisión.',
  'Get started': 'Empieza de cualquiera de las siguientes maneras:',
  'Get started.1': '→ Únete a nuestro {link:https://t.me/+7V5WE-6pjSEwZjEx^grupo de Telegram} para recibir apoyo. (Telegram admite la función "tap-to-translate" para cualquier idioma. Si necesitas ayuda, solo tienes que pedirla).',
  'Get started.2': '→ {link:contacto?n=1&m='+encodeURIComponent('Hola, me gustaría que Satoshi alojara mi tienda en el mercado comunitario en línea.')+'^Póngase en contacto con Satoshi} para empezar con el servicio completo.',
  'Get started.3': '→ Más información sobre el curso {link:cursos/distros^distros-course:title}.',
  'Get started.4': '→ {link:contact?n=1&m='+encodeURIComponent('Hola, me gustaría que Satoshi añadiera mi puesto de productos al mercado comunitario en línea.')+'^Póngase en contacto con Satoshi} para enlazar con un puesto de productos que ya gestione.',
  'Get started.5': '→ Acceda al {link:https://lnbits.satoshidnc.com^sistema de autoservicio de Satoshi} (o aloje el suyo propio) para configurar y gestionar su propia cuenta de comerciante siguiendo este breve tutorial:',
  'Get started.6': '{listitem}1. {link:media/2023/diy/01.mp4^Crear una cuenta de comerciante gratuita en Paraguay} (inglés, 2.8 min)', // 2:49
  'Get started.7': '{listitem}2. {link:media/2023/diy/02.mp4^Crear una tienda en línea} (inglés, 2 min)', // 1:55
  'Get started.8': '{listitem}3. {link:media/2023/diy/03.mp4^Crear zonas de envío} (inglés, 2.1 min)', // 2:08
  'Get started.9': '{listitem}4. {link:media/2023/diy/04.mp4^Crear un puesto} (inglés, 1.5 min)', // 1:30
  'Get started.10': '{listitem}5. {link:media/2023/diy/05.mp4^Añada productos a su tienda en línea} (inglés, 4 min)', // 4:03
  'Get started.11': '{listitem}6. {link:media/2023/diy/06.mp4^Activar la tramitación de pedidos} (inglés, 39 seg)', // 
  'Get started.12': '{listitem}7. {link:media/2023/diy/07.mp4^Añadir su tienda al mercado en línea} (inglés, 3.3 min)', // 3:16
  'Get started.13': '{listitem}8. {link:media/2023/diy/08.mp4^Ejemplo de compra de un cliente} (inglés, 2.3 min)', // 2:18
  'Get started.14': '{listitem}9. {link:media/2023/diy/09.mp4^Compruebe sus pedidos} (inglés, 2.3 min)', // 2:19
  'Get started.15': '{listitem}10. {link:media/2023/diy/10.mp4^Observaciones finales} (inglés, 3 min)', // 2:54 total: 23:51


'market-naddr-link': `https://mercado.satoshidnc.com`,

'pubkey:casa-vegana': '451004a83dc747a7ad62796a1882fe0731362ba0659498b52419a570ad9eb700',
'stall:casa-vegana': 'GpDUjcUgjarfqfN4dYptno',

'pubkey:more-energy': 'e099882085e9f43b5d6171c63c560dbc9c96dc49612590693d1477a4bae2a215',
'stall:more-energy': 'mny7wbCPVVkLzHzXHxbUZb',


}}

export default init_es