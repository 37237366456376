import { React } from 'react'
import { slug, icap, tr } from '../../../../global-pure.js'
import PageLayout from '../../../../containers/page/PageLayout.jsx'
import { Button } from '../../../../components/page/Section.jsx'
import AskLink from '../../../../components/AskLink.jsx'
import BugLink from '../../../../components/BugLink.jsx'
import TryItNowLink from '../../../../components/TryItNowLink.jsx'
import cardPic from '../../../../assets/apps/ng-wallet1.jpg'
import screenshot1 from '../../../../assets/apps/ng/wallet/screenshot-1.png'
import screenshot2 from '../../../../assets/apps/ng/wallet/screenshot-2.png'
import screenshot3 from '../../../../assets/apps/ng/wallet/screenshot-3.png'
import screenshot4 from '../../../../assets/apps/ng/wallet/screenshot-4.png'
import screenshot5 from '../../../../assets/apps/ng/wallet/screenshot-5.png'
import screenshot6 from '../../../../assets/apps/ng/wallet/screenshot-6.png'
import screenshot7 from '../../../../assets/apps/ng/wallet/screenshot-7.png'
import screenshot8 from '../../../../assets/apps/ng/wallet/screenshot-8.png'

export const card = {
  title: 'ng-wallet:title',
  blurb: 'ng-wallet:blurb',
  subtitle: 'ng-wallet:subtitle',
  routeBuilder: () => `/${slug(tr('apps'))}/${slug(tr('nuevoguarani'))}/${slug(tr('wallet'))}`,
  imgUrl: cardPic,
  call: 'see features',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const Wallet = () => {
  return (
    <PageLayout>
      <div className='side-divider'>
        <div className='content'>
          <h1>{icap(tr(card.title))}</h1>
          <h2>{tr(card.subtitle)}</h2>
          <h4>{icap(tr('status'))+':'}</h4>
          <ul>
            <li><p>{tr('ng-wallet:status')}</p></li>
          </ul>
          <h4>{icap(tr('platforms'))+':'}</h4>
          <ul>
            <li><p>{tr('software-platform:pwa')}, <TryItNowLink text={tr('try it now')} url='https://ng.satoshidnc.com'/>!</p></li>
          </ul>
          <h4>{icap(tr('key-features'))+':'}</h4>
          <ul>
          <li><p>{tr('ng-wallet:feature1')}</p></li>
          <li><p>{tr('ng-wallet:feature2')}</p></li>
          <li><p>{tr('ng-wallet:feature3')}</p></li>
          <li><p>{tr('ng-wallet:feature4')}</p></li>
          <li><p>{tr('ng-wallet:feature5')}</p></li>
          <li><p>{tr('ng-wallet:feature6')}</p></li>
          <li><p>{tr('ng-wallet:feature7')}</p></li>
          <li><p>{tr('ng-wallet:feature8')}</p></li>
          </ul>
          <h4>{icap(tr('specifications'))+':'}</h4>
          <ul>
          <li><p>{tr('ng-wallet:spec1')}</p></li>
          <li><p>{tr('ng-wallet:spec2')}</p></li>
          </ul>
          <h4>{icap(tr('license'))+':'}</h4>
          <ul>
          <li><p>{tr('ng-wallet:licensing')}<TryItNowLink text={tr('Satoshi Fairware License')} url='/licenses'/>{tr('ng-wallet:licensing2')}</p></li>
          </ul>
          <h4>{icap(tr('security'))+':'}</h4>
          <ul>
          <li><p>{tr('ng-wallet:security1')}</p></li>
          <li><p>{tr('ng-wallet:security2')}</p></li>
          </ul>
          <h4>{icap(tr('privacy'))+':'}</h4>
          <ul>
          <li><p>{tr('ng-wallet:privacy1')}</p></li>
          <li><p>{tr('ng-wallet:privacy2')}</p></li>
          </ul>
          <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
          <p>{icap(tr('Bugs?'))}{' '}<BugLink /></p>
        </div>
        <div className='screenshots'>
        <p className='image-container'>
        <img src={screenshot1} title={tr('auto-lock screen')} alt={tr('auto-lock screen')} />
        <img src={screenshot2} title={tr('home screen')} alt={tr('home screen')} />
        <img src={screenshot3} title={tr('discreet balance')} alt={tr('discreet balance')} />
        <img src={screenshot4} title={tr('amount entry')} alt={tr('amount entry')} />
        <img src={screenshot5} title={tr('QR presentation')} alt={tr('QR presentation')} />
        <img src={screenshot6} title={tr('scanner display')} alt={tr('scanner display')} />
        <img src={screenshot7} title={tr('success indicator')} alt={tr('success indicator')} />
        <img src={screenshot8} title={tr('light mode')} alt={tr('light mode')} />
        </p>
        </div>
      </div>

  </PageLayout>
)
}

export default Wallet