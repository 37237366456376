import marketPic from '../assets/market.png'

const naddr_de = 'naddr1qqjrxve5vgux2ven95erqce4956rscfs94snyvec956nsveevg6rsef3vfsnsqg6waehxw309aex2mrp0yh8xct5daeks6tyde3jucm0d5pzpt2wvdh9ef699v4t8yfrj4jrt5uaqalk55hpwjcfwkz4y508rgz4qvzqqqr4gvlkfqu6'

const init_de = (db) => { db['de-DE'] = {
  '{M}/{D}/{Y} {hh}:{mm}': '{D}/{M}/{Y} {hh}:{mm}',
  'Block Clock': 'Block Uhr',
  'Today is the ': 'Heute ist der ',
  ' day of the ': ' Tag des ',
  ' month of the ': ' Monats des ',
  ' year of Bitcoin in block time': ' Jahres von Bitcoin in Blockzeit',
  'ordinal-and': 'und',
  'first': 'erste',
  'second': 'zweite',
  'third': 'dritte',
  'fourth': 'vierte',
  'fifth': 'fünfte',
  'sixth': 'sechste',
  'seventh': 'siebte',
  'eighth': 'achte',
  'ninth': 'neunte',
  'tenth': 'zehnte',
  'eleventh': 'elfte',
  'twelfth': 'zwölfte',
  'thirteenth': 'dreizehnte',
  'fourteenth': 'vierzehnte',
  'fifteenth': 'fünfzehnte',
  'sixteenth': 'sechzehnte',
  'seventeenth': 'siebzehnte',
  'eighteenth': 'achtzehnte',
  'nineteenth': 'neunzehnte',
  'twentieth': 'zwanzigste',
  'thirtieth': 'dreißigste',
  'fortieth': 'vierzigste',
  'fiftieth': 'fünfzigste',
  'sixtieth': 'sechzigste',
  'seventieth': 'siebzigste',
  'eightieth': 'achtzigste',
  'ninetieth': 'neunzigste',
  'ord-twenty': 'zwanzig',
  'ord-thirty': 'dreißig',
  'ord-forty': 'vierzig',
  'ord-fifty': 'fünfzig',
  'ord-sixty': 'sechzig',
  'ord-seventy': 'siebzig',
  'ord-eighty': 'achtzig',
  'ord-ninety': 'neunzig',
  'events': 'Ereignisse',
  'ASU Meetup': 'ASU Treffen',
  'CDE Meetup': 'CDE Treffen',
  'DNC Reunión': 'DNC Treffen',
  'DNC Reunión (inglés)': 'DNC Meetup (Englisch)',
  'DNC Reunión (alemán)': 'DNC Meetup (Deustch)',
  'DNC Reunión (castillano)': 'DNC Meetup (Spanisch)',
  'ENC Meetup': 'ENC Treffen',
  'library': 'Bibliothek',
  'date': 'Datum',
  'price': 'Preis',
  'location': 'Standort',
  'technical consultation': 'Technische Konsultation',
  'Schedule a visit or video call to learn how we can serve you.': 'Vereinbaren Sie einen Besuch oder einen Videoanruf, um zu erfahren, wie wir Ihnen helfen können.',
  'learn more': 'mehr erfahren',
  'inquire now': 'jetzt anfragen',
  'purchase now': 'jetzt kaufen',
  'product details': 'Produktangaben',
  'browse now': 'jetzt stöbern',
  'read full article': 'den ganzen Artikel lesen',
  'begin the course': 'den Kurs beginnen',
  'register now': 'jetzt anmelden',
  'get it now': 'jetzt holen',
  'try it now': 'Jetzt ausprobieren',
  'see features': 'siehe Funktionen',
  'see apps': 'Apps ansehen',
  'course details': 'Kursdetails',
  'learn': 'lernen',
  'courses': 'Kurse',
  'apps': 'Apps',
  'nuevoguarani': 'neueguarani',
  'wallet': 'Geldbeutel',
  'why': 'warum',
  'services': 'Dienste',
  'meetup': 'Treffen',
  'market': 'Markt',
  'contact': 'Kontakt',
  'basics': 'Grundlagen',
  'lesson': 'Lektion',
  'next': 'weiter',
  'contact form': 'Kontakt-Formular',
  'How would you like to be contacted?': 'Wie möchten Sie kontaktiert werden?',
  'What is your message?': 'Was ist Ihre Botschaft?',
  'optional': 'optional',
  'required': 'erforderlich',
  'nostr': 'nostr',
  'name': 'Name',
  'e-mail': 'E-Mail',  
  'message': 'Nachricht',
  'send': 'senden',
  'check required fields': 'Pflichtfelder ankreuzen',
  'or': 'oder',
  'Questions?': 'Noch Fragen?',
  'ask now': 'jetzt fragen',
  'Bugs?': 'Fehler?',
  'report a bug': 'einen Fehler melden',
  'Hi, I would like to ask a question about': 'Hallo, ich würde gerne eine Frage stellen über',
  'techcons:question': 'Hallo, ich würde gerne eine Frage stellen über Technische Beratung.\n...\n',
  'webdev:question': 'Hallo, ich würde gerne eine Frage stellen über Web-Entwicklung.\n...\n',
  'compfix:question': 'Hallo, ich würde gerne eine Frage stellen über Computer-Reparatur.\n...\n',
  'Educational Resources': 'Pädagogische Ressourcen',
  'Copyright © 2023. All rights reserved.': 'Copyright © 2023-2024. Alle Rechte vorbehalten.',
  'dates': 'Daten',
  'schedule': 'Zeitplan',
  'course options': 'Kursoptionen',
  'publication date': 'Datum der Veröffentlichung',
  'inquire about check-out': 'sich über die Ausleihe erkundigen',
  'this course is for': 'Dieser Kurs ist für',
  'in this course, you will': 'In diesem Kurs werden Sie',
  'this course includes': 'Dieser Kurs umfasst',
  'NOT included in course': 'NICHT im Kurs enthalten',
  'prerequisites': 'Voraussetzungen',
  'payment methods': 'Zahlungsverfahren',
  'registration': 'Anmeldung',
  'everyone': 'alle',
  'owners': 'Eigentümer',
  'business owners': 'Unternehmenseigentümer',
  'owners of businesses which already have delivery services': 'Inhaber von Unternehmen, die bereits Lieferdienste haben',
  'all employees who will handle online orders': 'alle Mitarbeiter, die Online-Bestellungen bearbeiten',
  'high net worth individuals': 'vermögende Privatpersonen',
  'individuals with savings': 'Personen mit Ersparnissen',
  'individuals with income to spare': 'Personen mit geringem Einkommen',
  'none': 'keine',
  'Guarani cash': 'Guarani, Bargeld',
  'Guarani, bank deposit': 'Guarani, Bankeinlage',
  'Dollars, PayPal': 'Dollars, PayPal',
  'Dollars, bank deposit': 'Dollars, Bankeinlage',
  'in-person, upon payment': 'Persönlich, gegen Bezahlung',
  'online, effective upon receipt of payment': 'online, wirksam nach Zahlungseingang',

  'Featured video!': 'Ausgewähltes Video!',
  'Click to watch now!': 'Zum Anschauen jetzt klicken!',


  /* LIBRARY PAGE */

  'library:title': 'Bitcoin-Bibliothek der Gemeinschaft',
  'library:intro': 'Wir haben die folgenden physischen Bücher in unserer Bibliothek. Um Zugang zu einem Buch zu erhalten, kontaktieren Sie uns bitte. Wir {link:lightning:biblioteca@satoshidnc.com^nehmen Spenden} für den Kauf dieser und weiterer Bücher an, um sie unserer Gemeinschaft zur Verfügung zu stellen.',
  'library:other-title': 'Zusätzliche Ressourcen',
  'library:other-intro': 'Die folgenden Bücher von Nelson Cardozo sind online verfügbar.',

  'el-patron-bitcoin:title': 'El patrón Bitcoin',
  'el-patron-bitcoin:subtitle': 'La alternativa descentralizada a los bancos centrales',
  'el-patron-bitcoin:author': 'Saifedean Ammous (Autor), Mercedes Vaquero Granados (Übersetzer)',
  'el-patron-bitcoin:date': 'Oktober 2, 2018',
  'el-patron-bitcoin:link': '/contact?n=1&m=Sehr%20geehrter%20Herr%2C%20ich%20habe%20gehört%2C%20dass%20Sie%20das%20Buch%20"El%20patrón%20Bitcoin"%20im%20Distrito%20del%20Nueva%20Colombia%20verfügbar%20haben.%20Ich%20möchte%20es%20gerne%20ausleihen.%20Sie%20können%20sich%20mit%20mir%20in%20Verbindung%20setzen%2C%20indem%20Sie%20die%20von%20mir%20angegebenen%20Kontaktinformationen%20verwenden.%20Ich%20danke%20Ihnen.',

  'conociendo-bitcoin:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin:subtitle': '¿Se puede llegar a comprender todo sobre bitcoin? Se se puede.',
  'conociendo-bitcoin:author': 'Nelson Cardozo (Autor)',
  'conociendo-bitcoin:date': '',

  'conociendo-bitcoin-ii:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin-ii:subtitle': 'Orígens del dinero, que es bitcoin, aprende a ahorrar con bitcoins',
  'conociendo-bitcoin-ii:author': 'Nelson Cardozo (Autor)',
  'conociendo-bitcoin-ii:date': '',


  'meetup:title': 'Herzlich willkommen!',
  'meetup:location': 'Distrikt Nueva Colombia',
  'meetup:date': 'Sonntag, 15. September, um 10:00 Uhr.',
  'meetup:price': '5 Gs (im Grunde nichts), bezahlt über das Lightning-Netzwerk.',
  'meetup:content.1': 'Bitte melden Sie sich für die Teilnahme an (Check-in ist erforderlich). Dies hilft uns bei der Planung und sorgt dafür, dass sich die Gruppe auf die aktive Teilnahme an der Bitcoin-Kreislaufwirtschaft konzentriert.',
  'meetup:content.2': 'Für den genauen Standort und/oder Hilfe bei der Anmeldung nehmen Sie bitte über {link:https://chat.whatsapp.com/KiFnEElpETfH308PtN47Yr^WhatsApp} Kontakt auf.',


  'Bitcoin Courses': 'Bitcoin-Kurse',
  'courses:intro': 'Satoshi’s Kurse sind darauf ausgerichtet, das Bewusstsein für Bitcoin und die Einführung von Bitcoin in Paraguay zu fördern, zum gegenseitigen Nutzen von lokalen Unternehmen, Einzelpersonen, ausländischen Besuchern und Einwanderern. Gemeinsam bauen wir die Wirtschaft der Zukunft in Paraguay auf. Um den Kontext unserer Mission zu verstehen, lesen Sie bitte unsere kurze Einführung:',
  'Course Selection': 'Kursauswahl',
  'Course Selection.1': 'Die oben genannten Kurse sind jeweils auf einen bestimmten Anwendungsfall ausgerichtet. Wir glauben, dass dies den optimalen Weg zum Erfolg für unsere Kunden und unsere Gemeinschaft darstellt. Bitte sehen Sie sich die Kursbeschreibungen an und wählen Sie den/die Kurs(e) aus, der/die am besten zu Ihrer speziellen Situation passt/passen. Wenn Sie Fragen haben, wenden Sie sich bitte an uns. Sie könnten auch daran interessiert sein, ein individuelles Beratungsgespräch für Fälle zu vereinbaren, in denen ein Gruppentraining nicht gewünscht oder verfügbar ist.',
  'Course Selection.2': '(Hinweis für ganztägige Kurse: Mahlzeiten sind NICHT vorgesehen. Wir empfehlen Ihnen, Ihr eigenes Mittagessen mitzubringen oder die örtlichen Restaurants zu besuchen).',
  'Refund Policy': 'Erstattungspolitik',
  'Refund Policy.1': 'Wir sind bestrebt, die bestmöglichen Schulungs- und Unterstützungsdienste für die langfristige Betreuung unserer Kunden anzubieten. Ihr Erfolg ist der Maßstab für unseren Erfolg. Wir sind sicher, dass Sie von Inhalt und Qualität unserer Kurse beeindruckt sein werden.',
  'Refund Policy.2': 'Falls ein Kurs aus irgendeinem Grund vor dem Startdatum abgesagt werden muss, werden wir uns nach besten Kräften bemühen, die Kursanmeldungen zu erstatten. Bitte beachten Sie jedoch, dass wir bei Kursen, die Hardware beinhalten, die Kosten für die Ausrüstung nicht erstatten können; die gekaufte Ausrüstung gehört Ihnen. Rückerstattungen nach dem Startdatum liegen im Ermessen von Satoshi, D.N.C. Wir danken Ihnen für Ihr Verständnis.',

  'basics:subtitle': 'Dies ist ein dreiwöchiger Kurs, der die Grundlagen von Bitcoin vermittelt.',
  'basics:desc1': 'Bitte fahren Sie mit dem Bitcoin Magazine’s ',
  'basics:desc2': '21 Days of Bitcoin',
  'basics:desc3': ' Kurs fort.',
  'Week {n}:': 'Woche {n}:',

  'basics-course:date': '14. September - 7. Oktober 2023',
  'basics-course:title': 'Bitcoin-Grundlagen',
  'basics-course:blurb': 'Macht Sie schlau über Bitcoin mit allen wesentlichen Informationen, um zu verstehen, wie Bitcoin funktioniert.',
  'basics-course:subtitle': 'Dies ist ein dreiwöchiger Kurs, der die Grundlagen von Bitcoin vermittelt.',
  'basics:option1': 'Zeitplan A: 1 Stunde pro Tag für drei volle Wochen, 8:00 - 9:00 Uhr.',
  'basics:option2': 'Zeitplan B: 3 volle Tage, ein Tag pro Woche (am Wochenende)',
  'basics:bullet1': 'Erfahren Sie alles, was Sie wissen müssen, um Ihre Bitcoin-Reise zu beginnen.',
  'basics:bullet2': 'Lernen Sie Begriffe, Konzepte und Vokabeln, um mit Bitcoinern zu sprechen und sie zu verstehen.',
  'basics:bullet3': 'Führen Sie Ihren ersten Umtausch von Guarani in Bitcoin durch.',
  'basics:bullet4': 'Tätigen Sie Ihren ersten Kauf mit Bitcoin (im Wert von 10.000 Gs, inklusive).',

  'business-course:date': 'wird noch festgelegt',
  'business-course:title': 'Bitcoin für Unternehmen',
  'business-course:blurb': 'Stattet Geschäftsinhaber in Paraguay mit Point-of-Sale-Tools und Schulungen aus.',
  'business-course:subtitle': 'Dieser Kurs vermittelt Geschäftsinhabern in Paraguay die nötigen Werkzeuge und Schulungen für den Point-of-Sale.',
  'business:option1': '2 volle Tage, individuell geplant 1 Tag außer Haus, 1 Tag vor Ort',
  'business:bullet1': 'Kassensystem für Bitcoin und Guarani-Bargeld',
  'business:bullet2': 'Kassiererschulung nur für den Eigentümer/Registrator (der Eigentümer kann problemlos andere Kassierer schulen)',
  'business:bullet3': 'Ein Monat Wallet-Service (kann verlängert werden, wenn der Builders-Kurs nicht in Anspruch genommen wird)',
  'business:bullet4': 'Alles, was Sie für Verkäufe in Bitcoin brauchen',
  'business:bullet5': 'Ein Schild für Ihr Geschäft, das darauf hinweist, dass Sie Bitcoin/Lightning-Zahlungen akzeptieren',
  'business:bulletx1': 'Schulung für andere Kassiererinnen (der Eigentümer wird problemlos andere Kassiererinnen schulen)',
  'business:bulletx2': 'Fortlaufender Wallet-Service (kann separat erworben werden, wenn der Builders Course nicht genutzt wird)',
  'business:bulletx3': 'Im Grundkurs behandeltes Material (dringend empfohlen, aber nicht erforderlich)',
  'business:prereq1': 'Kompatibles Smartphone oder anderes Gerät für die Verkaufsstelle (kann auf Wunsch gegen Aufpreis mitgeliefert werden)',
  'business:prereq2': 'Internet am Verkaufsort',

  'distros-course:date': 'wird noch festgelegt',
  'distros-course:title': 'Bitcoin für Händler',
  'distros-course:blurb': 'Bringt Händlern in Paraguay bei, wie sie Online-Bestellungen mit Bitcoin annehmen können.',
  'distros-course:subtitle': 'In diesem Kurs lernen Händler, wie sie in Paraguay Online-Bestellungen mit Bitcoin annehmen können.',
  'distros:option1': 'Ein ganzer Tag',
  'distros:bullet1': 'Schulung zum Online-Bestellsystem',
  'distros:bullet2': 'Ein Monat Wallet-Service (kann verlängert werden, wenn der Builders-Kurs nicht in Anspruch genommen wird)',
  'distros:bullet3': 'Online-Marktplatz',
  'distros:bullet4': 'Alles, was Sie brauchen, um einen Online-Shop zu betreiben, der Bitcoin akzeptiert',
  'distros:bulletx1': 'Dateneingabe für Ihre Produktlinie (kann separat in Auftrag gegeben werden)',
  'distros:bulletx2': 'Fortlaufender Wallet-Service (kann separat erworben werden, wenn der Builders Course nicht genutzt wird)',
  'distros:bulletx3': 'Im Grundkurs behandeltes Material (dringend empfohlen, aber nicht erforderlich)',
  'distros:prereq1': 'Kompatibler Computer oder anderes Gerät',
  'distros:prereq2': 'Internet am Ort der Bestellannahme',

  'builders-course:date': 'wird noch festgelegt',
  'builders-course:title': 'Bitcoin für Bauherren',
  'builders-course:blurb': 'Führt Sie durch den Aufbau Ihres eigenen Bitcoin- und Lightning-Knotens in Paraguay.',
  'builders-course:subtitle': 'Dieser Kurs führt die Teilnehmer durch den Aufbau ihres eigenen Bitcoin- und Lightning-Knotens in Paraguay.',
  'builders:option1': '1 Stunde pro Tag für drei volle Wochen, 8:00 - 9:00 Uhr.',
  'builders:bullet1': 'Von Studenten konstruierter Bitcoin-Knoten',
  'builders:bullet2': 'Vollständige Kopie der Bitcoin-Blockchain (500 GB Download-Ersparnis)',
  'builders:bullet3': 'Selbstverwahrungs-Blitzknoten',
  'builders:bullet4': 'Finanzierter und ausgeglichener Blitzzahlungskanal',
  'builders:bullet5': 'Alles, was Sie für die Wartung Ihres Knotens benötigen',
  'builders:bulletx1': 'Kontinuierlicher Ausgleich der Kanäle (da es sich um Mittel des Eigentümers handelt)',
  'builders:bulletx2': 'Wartung (kann separat erworben werden)',
  'builders:prereq1': 'Grundkurs (oder Test-Out)',
  'builders:prereq2': 'Internet am vorgesehenen Standort des Knotens',

  'investors-course:date': 'wird noch festgelegt',
  'investors-course:title': 'Bitcoin für Investoren',
  'investors-course:blurb': 'Informiert Anleger über die verschiedenen Möglichkeiten, in Bitcoin in Paraguay zu investieren.',
  'investors-course:subtitle': 'Dieser Kurs zeigt Anlegern die verschiedenen Möglichkeiten, in Paraguay in Bitcoin zu investieren.',
  'investors:option1': '3 volle Tage, ein Tag pro Woche (Sonntag)',
  'investors:bullet1': 'Der Stoff des Grundkurses, mit anderen Schwerpunkten',
  'investors:bullet2': 'Unterweisung in Bitcoin-Sicherheitsprotokollen',
  'investors:bullet3': 'Anleitung zum Bitcoin-Mining als Investition',
  'investors:bullet4': 'Ihre ersten $50, die Sie in selbstverwahrte Bitcoin investieren (Sie können mehr hinzufügen)',

  'why:title': 'Warum wandert Bitcoin nach Paraguay?',
  'why:blurb': 'Bitcoin und Paraguay sind wie geschaffen für den Himmel! Dieser kurze Artikel erklärt die wichtigsten Punkte, die Sie über die Verlagerung des Bitcoin-Bergbaus nach Paraguay wissen müssen, und welche Auswirkungen dies auf die Welt hat.',
  'why.1': 'Paraguay ist aus folgenden Gründen der ideale Standort für Bitcoin-Mining:',
  'why.2': 'tag:ol',
  'why.2.1': 'Paraguay hat zehnmal mehr Strom zur Verfügung, als es derzeit nutzen kann, was derzeit eine verlorene Einnahme darstellt. Bitcoin-Miner können diese Energie problemlos nutzen und Paraguay im Gegenzug Einnahmen verschaffen. Es ist eine Win-Win-Situation für die Bitcoin-Mining-Unternehmen und für das Land Paraguay.',
  'why.2.2': 'Der Vertrag zwischen Paraguay und Brasilien soll dieses Jahr, 2023, neu verhandelt werden. Das Bitcoin-Mining-Potenzial in Paraguay gibt dem Land ein Druckmittel, um bessere und profitablere Bedingungen als in der Vergangenheit auszuhandeln. Bitcoin-Mining-Betriebe können sich sehr schnell ansiedeln und in Betrieb nehmen, und die Vorteile sind unmittelbar.',
  'why.2.3': 'Paraguays Energieerzeugung besteht zu 99,9 % aus sauberer Wasserkraft. Das bedeutet, dass Bitcoin-Mining-Einrichtungen, die von weniger umweltfreundlichen Stromnetzen nach Paraguay umziehen, einen positiven und quantifizierbaren Beitrag zu einer besseren Welt leisten.',
  'why.3': 'Die drei oben genannten Punkte sind der natürliche Vorteil von Unternehmen, die im Rahmen ihrer Geschäftstätigkeit Gewinne erzielen wollen. Paraguays vergleichsweise günstige Energiepreise machen es zu einem Gewinn für die Bitcoin-Mining-Industrie, während der inländische Verbrauch von Strom einen Gewinn für das Land darstellt.',
  'why.4': 'Da der Druck auf das Bitcoin-Mining durch den Wettbewerb und die Umstände immer größer wird, werden immer mehr Mining-Einrichtungen ihren Standort nach Paraguay verlagern wollen, insbesondere aufgrund der Bitcoin-Halbierung, die im Frühjahr 2024 ansteht. Nach der "Halbierung" werden nur die am besten positionierten und kosteneffizientesten Mining-Unternehmen in der Lage sein, zu überleben und infolgedessen einen größeren Anteil an den Mining-Einnahmen zu erhalten. In diesem hart umkämpften Geschäft hat Paraguay die Chance, die führende Nation für Bitcoin-Mining in der Welt zu werden.',
  'why.5': 'Für ein kleines Land wie Paraguay, das von der Außenwelt abgeschnitten ist, stellt dies eine wertvolle Wachstumschance dar. Das Interesse von Ausländern aus allen Teilen der Welt wird unweigerlich zu mehr als nur Energieeinnahmen führen. Es wird auch die Einfuhr von Hardware und Technologie, Einnahmen aus dem Tourismus, Beiträge zur allgemeinen Wirtschaft und mehr technologische Entwicklung und Innovation geben.',
  'why.6': 'Leicht übersehen wird die Tatsache, dass der neu geschürfte Bitcoin, der als Einkommen aus dem Mining-Betrieb erwirtschaftet wird, im Allgemeinen den Besitzern der Mining-Hardware gehört, und diese Personen und Unternehmen werden ein persönliches Interesse an Paraguay haben. Ihre Einnahmen - das Geld der Zukunft (d.h. Bitcoin) - werden versuchen, in die Wirtschaft Paraguays einzusickern und der allgemeinen Bevölkerung die Möglichkeit eröffnen, neben den Guaraní Bitcoin zu verdienen. Für die Nation wird dies wiederum ein weiterer Kanal für IVA-Einnahmen sein, die durch paraguayische Unternehmen fließen.',
  'why.7': 'So wie die in Itaipu erzeugte Energie saubere Energie ist, so ist auch der Bitcoin, der im Mining-Betrieb erzeugt wird, sauberes Geld ohne negative Vorgeschichte. Wenn Paraguay in diesem entscheidenden Jahr klug handelt, kann es dem Land gelingen, die Stigmata der Vergangenheit abzulegen und eine Zukunft des Wohlstands zu sichern, die für alle Seiten ein Gewinn sein wird: ein Gewinn für die Regierung des Landes, ein Gewinn für die einzelnen Bürger und ein Gewinn für Ausländer und den Planeten als Ganzes. So reichlich wie der mächtige Iguazú den paraguayischen Haushalten heute Licht spendet, so reichlich wird er ihnen morgen finanzielle Sicherheit bieten.',

  'status': 'Status',
  'platforms': 'unterstützte Plattformen',
  'software-platform:android-native': 'Android nativ',
  'software-platform:web-browser': 'Web - alle Geräte',
  'software-platform:android-native-discontinued': 'Android nativ - Ausgelaufen',
  'software-platform:pwa': 'Progressive Webanwendung (PWA) - alle Geräte',
  'key-features': 'Hauptmerkmale',
  'specifications': 'technische Daten',
  'license': 'Quellcode und Lizenzierung',
  'security': 'Sicherheitsüberlegungen',

  'ng:title': 'Neue Guaraní',
  'ng:blurb': 'Die paraguayische Verkaufsstellenerfahrung wird durch Peer-to-Peer-Electronic-Cash erweitert. Mit diesen Apps macht das Kaufen und Verkaufen wieder Spaß!',
  'ng:subtitle': 'Verkaufsstellen-App für die breite Masse',
  'ng:content': '',
  'ng:status': 'Die App befindet sich in aktiver Entwicklung und wird derzeit in der Praxis getestet.',
  'ng:feature1': 'Funktioniert sowohl mit Papiergeld als auch mit elektronischem Bargeld (im selben Verkauf!)',
  'ng:feature2': 'So einfach wie ein Taschenrechner zu bedienen',
  'ng:feature3': 'Visuelle Produktauswahl',
  'ng:feature4': 'Barcode-Unterstützung',
  'ng:feature5': 'Integrierbar mit anderer Software',
  'ng:spec1': 'Für elektronische Bargeldfunktionen ist Bitcoin die Währung und Satoshi die Einheit, wobei Zahlungen über das Lightning-Netzwerk erfolgen.',
  'ng:spec2': 'Die Geldbörse für elektronisches Geld wird von LNbits betrieben (konfigurierbar; selbst gehostet oder verwahrt)',
  'ng:spec3': 'Produktpreise und Bildmaterial können von einem NostrMarket-Stand bezogen werden (über LNbits)',
  'ng:licensing': 'Diese Software ist quelloffen und unterliegt der ',
  'ng:licensing2': ' Lizenz.',
  'ng:security': 'Alle Anwendungsdaten werden in einer einzigen Datenbank auf dem lokalen Gerät gespeichert (IndexedDB). Sensible Konfigurationsdaten wie Wallet-Schlüssel mit Lesezugriff oder Vollzugriff werden nicht erneut angezeigt. Kein Passwortschutz. Den Nutzern wird empfohlen, regelmäßig Gelder auf eine sicherere Wallet zu übertragen, um das Risiko zu minimieren, falls das Gerät kompromittiert wird.',

  'ng-pos:title': 'Neue Guaraní PoS',
  'ng-pos:blurb': 'Die paraguayische Verkaufsstellenerfahrung wird durch Peer-to-Peer-Electronic-Cash erweitert. Mit diesen Apps macht das Kaufen und Verkaufen wieder Spaß!',
  'ng-pos:subtitle': 'Verkaufsstellen-App für die breite Masse',
  'ng-pos:content': '',
  'ng-pos:status': 'Die App befindet sich in aktiver Entwicklung und wird derzeit in der Praxis getestet.',
  'ng-pos:feature1': 'Funktioniert sowohl mit Papiergeld als auch mit elektronischem Bargeld (im selben Verkauf!)',
  'ng-pos:feature2': 'So einfach wie ein Taschenrechner zu bedienen',
  'ng-pos:feature3': 'Visuelle Produktauswahl',
  'ng-pos:feature4': 'Barcode-Unterstützung',
  'ng-pos:feature5': 'Integrierbar mit anderer Software',
  'ng-pos:spec1': 'Für elektronische Bargeldfunktionen ist Bitcoin die Währung und Satoshi die Einheit, wobei Zahlungen über das Lightning-Netzwerk erfolgen.',
  'ng-pos:spec2': 'Die Geldbörse für elektronisches Geld wird von LNbits betrieben (konfigurierbar; selbst gehostet oder verwahrt)',
  'ng-pos:spec3': 'Produktpreise und Bildmaterial können von einem NostrMarket-Stand bezogen werden (über LNbits)',
  'ng-pos:licensing': 'Diese Software ist quelloffen und unterliegt der ',
  'ng-pos:licensing2': ' Lizenz.',
  'ng-pos:security': 'Alle Anwendungsdaten werden in einer einzigen Datenbank auf dem lokalen Gerät gespeichert (IndexedDB). Sensible Konfigurationsdaten wie Wallet-Schlüssel mit Lesezugriff oder Vollzugriff werden nicht erneut angezeigt. Kein Passwortschutz. Den Nutzern wird empfohlen, regelmäßig Gelder auf eine sicherere Wallet zu übertragen, um das Risiko zu minimieren, falls das Gerät kompromittiert wird.',

  'ng-wallet:title': 'Neue Guaraní Geldbeutel',
  'ng-wallet:blurb': `Peer-to-Peer-Bargeldtausch leicht gemacht, in der lokalen Währung und Sprache!`,
  'ng-wallet:subtitle': 'Eine Brieftaschen-App für die Nation.',
  'ng-wallet:content': '',
  'ng-wallet:status': 'PWA Beta-Version.',
  'ng-wallet:download-apk': `APK jetzt herunterladen!`,
  'ng-wallet:feature1': 'Minimalistische Benutzeroberfläche für einfache, schnelle und bequeme Nutzung.',
  'ng-wallet:feature2': 'Alice initiiert einen Sende- oder Empfangsvorgang und gibt den Betrag ein, um einen QR-Code zu erstellen, den Bob scannen und bestätigen kann.',
  'ng-wallet:feature3': 'Alle Beträge und Salden werden in der Währung Guaraní (₲, PYG) eingegeben und angezeigt.',
  'ng-wallet:feature4': 'Das Guthaben in der Brieftasche ist außer Sichtweite, aber leicht ablesbar.',
  'ng-wallet:feature5': 'PIN-Schutz.',
  'ng-wallet:feature6': 'Funktioniert nahtlos mit anderen Anwendungen.',
  'ng-wallet:feature7': 'Lichtmodus und Nachtmodus.',
  'ng-wallet:feature8': 'Bequemer Link (QR-Code) zum Teilen der App.',
  'ng-wallet:spec1': 'Zahlungen werden über das Lightning-Netzwerk gesendet.',
  'ng-wallet:spec2': 'Powered by LNbits (derzeit nur für Verwahrer; Konfigurationsoption für selbst gehostete Wallets folgt in Kürze)',
  'ng-wallet:licensing': 'Diese Software ist quelloffen und unterliegt der ',
  'ng-wallet:licensing2': '.',
  'ng-wallet:security1': 'Die Schlüssel werden nie angezeigt.',
  'ng-wallet:security2': 'Der PIN-Schutz schützt die Brieftasche vor unbefugter Benutzung.',
  'ng-wallet:privacy1': 'Die App überträgt keine persönlichen Daten, außer denen, die im Rahmen des Sendens und Empfangens offensichtlich sind.',
  'ng-wallet:privacy2': 'Die Verwahrstelle erhält Transaktionsdetails, aber keine personenbezogenen Daten.',


  'products': 'Produkte',

  'bitcoin-node:title': 'Bitcoin-Knoten',
  'bitcoin-node:blurb': 'Ihr Bitcoin-Node ist Ihr privates Gateway, um Ihre Bitcoin-Transaktionen, Wallet-Adressen, Kontostände, gezahlte Gebühren oder andere Daten, die sich auf der Bitcoin-Blockchain befinden, zu verifizieren, sowie Ihr "erlaubnisfreies" Terminal, um jede Transaktion im Bitcoin-Netzwerk zu initiieren.',
  'bitcoin-node:subtitle': '',
  'bitcoin-node:affiliate': '',
  'bitcoin-node:content': '',

  'ant-s19j:title': 'Antminer S19J Pro+ 120TH Bitcoin Miner',
  'ant-s19j:blurb': 'Einen Miner zu kaufen und ihn in Paraguay zu hosten, ist die beste Art, in Bitcoin zu investieren, weil er Sie mit nachweislich unverfälschten Bitcoin direkt an Ihr eigenes Wallet zu den besten Strompreisen bezahlt.',
  'ant-s19j:subtitle': '',
  'ant-s19j:affiliate': 'Wenn Sie über den Affiliate-Link von Satoshi, D.N.C. kaufen, haben Sie Anspruch auf die Vorteile einer Mitgliedschaft während der gesamten Lebensdauer des Produkts.',
  'ant-s19j:content': '',

  'atm-2way:title': 'BitPoint Ultra 2-Way Bitcoin ATM',
  'atm-2way:blurb': 'Dieses Produkt ermöglicht den wechselseitigen Umtausch zwischen Bitcoin und Papierwährung. Dies kann eine großartige Möglichkeit sein, Ihre Kunden zu unterstützen und die Kreislaufwirtschaft zu fördern.',
  'atm-2way:subtitle': '',
  'atm-2way:affiliate': 'Wenn Sie über den Affiliate-Link von Satoshi, D.N.C. kaufen, haben Sie Anspruch auf die Vorteile einer Mitgliedschaft während der gesamten Lebensdauer des Produkts.',
  'atm-2way:content': '',

  'techcons:title': 'technische Beratung',
  'techcons:subtitle': 'Wir verfügen über ein breites Spektrum an technischem Wissen und Erfahrung und können Ihnen wahrscheinlich Ratschläge geben, was zu tun und was zu vermeiden ist, während Sie Ihre Mission vorantreiben.',
  'tech-consult:desc1a': 'Satoshi, D.N.C. bietet einen umfassenden technischen Service, um Sie in allen Aspekten von Bitcoin und Bitcoin-bezogenen Technologien zu unterstützen. Um loszulegen, reservieren Sie noch heute einen Beratungstermin, indem Sie unseren Stand auf dem ',
  'tech-consult:desc1b': 'Gemeinschaftsmarkt',
  'tech-consult:desc1c': ' besuchen und eine Online-Bestellung für Ihre erste Beratung aufgeben. Bitte geben Sie ausreichend Kontaktinformationen und Terminwünsche an. Sollten wir aus irgendeinem Grund nicht in der Lage sein, Sie zu kontaktieren oder Ihren Beratungswunsch zu erfüllen, wird Ihre Reservierung zurückerstattet. Sobald Sie Ihre Beratung erfolgreich reserviert haben, werden Sie vor der eigentlichen Beratung kontaktiert, um den Termin zu bestätigen.',

  'webdev:title': 'Web-Entwicklung',
  'webdev:blurb': 'Ein virtuelles Schaufenster verschafft Ihnen einen Wettbewerbsvorteil, da die Kunden Sie kennenlernen und Produkte bestellen können, ohne ihr Haus zu verlassen.',
  'webdev-link-kludge': '/kontakt?n=1&m=Hallo%2C%20ich%20w%C3%BCrde%20gerne%20eine%20Frage%20stellen%20%C3%BCber%20Web-Entwicklung.%0A...%0A',

  'compfix:title': 'Computer-Reparatur',
  'compfix:blurb': 'Reinigungs- und Reparaturdienste für Computerhardware und -software.',
  'compfix-link-kludge': '/kontakt?n=1&m=Hallo%2C%20ich%20w%C3%BCrde%20gerne%20eine%20Frage%20stellen%20%C3%BCber%20Computer-Reparatur.%0A...%0A',

  'market:title': 'Gemeinschaftsmarkt Online',
  'market:subtitle': 'Der Online-Markt ist ein Ort, an dem Sie lokale Produzenten und Dienstleister finden und online einkaufen können, um sie in ausgewählte Gebiete zu liefern.',
  '(default)': '',
  '(default).1': `Schnelle Link: {link:https://mercado.satoshidnc.com^Jetzt online einkaufen!} | {link:https://btcmap.org/map#12/-25.23680/-57.43610^Gemeindekarte ansehen}`,
  'What makes this a great platform': 'Was diese Plattform so großartig macht',
  'What makes this a great platform.1': 'Peer-to-Peer. Anbieter und Kunden können auf Wunsch direkt miteinander interagieren, und die Anbieter können ihre Kundenbeziehungen überallhin mitnehmen.',
  'What makes this a great platform.2': 'Nicht-exklusiv. Satoshi dient dazu, Kunden mit Anbietern zu verbinden, aber die Protokolle sind für beide Seiten offen, auch andere Marktplätze zu hosten oder zu nutzen.',
  'What makes this a great platform.3': 'Respektieren der Privatsphäre. Die Kommunikation ist durch Ihre privaten Schlüssel gesichert und Sie geben nur die Informationen weiter, die Sie weitergeben möchten.',
  'What makes this a great platform.4': 'Lightning-fähig. Bezahlen Sie sofort und sicher mit jeder Lightning-kompatiblen Geldbörse.',
  'How does Satoshi profit?': 'Wie profitiert Satoshi?',
  'How does Satoshi profit?.1': 'Satoshi verdient, indem er Verkäufern Schulungskurse anbietet und/oder die anfänglichen Bemühungen, Produkte online zu stellen.',
  'How does Satoshi profit?.2': 'Das Ziel ist es, ein florierendes Peer-to-Peer-Netzwerk zu fördern, das nicht von Satoshi als einzigem Ausfallpunkt abhängig ist.',
  'How does Satoshi profit?.3': '(Wenn Sie aus Gründen der Redundanz und/oder der Abwechslung einen Marktplatz einrichten möchten, kann Satoshi Sie dabei beraten).',
  'How it works-customers and clients': 'Wie der Online-Markt für Kunden und Auftraggeber funktioniert',
  'How it works-customers and clients.1': '1: Einkaufen. 2: Auschecken. 3: Verfolgen Sie alle weiteren Informationen zur Lieferung oder Abholung.',
  'How it works-customers and clients.2': `btn:[Jetzt einkaufen!](https://mercado.satoshidnc.com)`,
  'How it works-businesses': 'Wie der Online-Markt für Unternehmen funktioniert',
  'How it works-businesses.1': 'Privatpersonen oder Unternehmen können einen eigenen Marktstand betreiben oder die Dienste von Satoshi in unterschiedlichem Umfang für die Abwicklung ihrer Online-Bestellungen in Anspruch nehmen.',
  'How it works-businesses.2': 'Die ideale Option für den technisch versierten Unternehmer ist es, sein eigenes Portemonnaie und seinen eigenen Produktstand zu betreiben und einfach zu beantragen, kostenlos auf der Satoshi Marktplatzseite aufgeführt zu werden.',
  'How it works-businesses.3': 'Die nächstbeste Option für den industriellen Geschäftsinhaber ist die Nutzung von Satoshi, um seinen Produktstand zu vernachlässigbaren Kosten zu hosten, und die Produktliste zu erstellen und die Bestellungen täglich selbst zu verwalten.',
  'How it works-businesses.4': 'Die nachstehenden Videos sind für diejenigen hilfreich, die eine der beiden ersten Optionen verfolgen möchten. Die einfachste Option für vielbeschäftigte Inhaber ist es, Satoshi zu bitten, die Abwicklung von Online-Verkäufen auf Provisionsbasis zu übernehmen.',
  'Get started': 'Sie können auf eine der folgenden Arten einsteigen:',
  'Get started.1': '→ Treten Sie unserer {link:https://t.me/+7V5WE-6pjSEwZjEx^Telegram-Gruppe} bei, um Unterstützung zu erhalten. (Telegram unterstützt Tap-to-Translate für jede Sprache. Wenn du Hilfe brauchst, frag einfach).',
  'Get started.2': '→ {link:kontakt?n=1&m='+encodeURIComponent('Hallo, ich möchte, dass Satoshi mein Geschäft im Online-Community-Markt hostet!')+'^Kontaktieren Sie Satoshi}, um den vollen Service in Anspruch zu nehmen.',
  'Get started.3': '→ Erfahren Sie mehr über den Kurs {link:kurse/distros^distros-course:title}.',
  'Get started.4': '→ Setzen Sie sich mit {link:kontakt?n=1&m='+encodeURIComponent('Hallo, ich möchte, dass Satoshi meinen Produktstand in den Online-Community-Markt einstellt!')+'^Satoshi in Verbindung}, um einen Link zu einem Produktstand zu erstellen, den Sie bereits verwalten.',
  'Get started.5': '→ Greifen Sie auf das {link:https://lnbits.satoshidnc.com^Selbstbedienungssystem von Satoshi} zu (oder hosten Sie Ihr eigenes), um Ihr eigenes Händlerkonto einzurichten und zu verwalten, indem Sie dieser kurzen Anleitung folgen:',
  'Get started.6': '{listitem}1. {link:media/2023/diy/01.mp4^Erstellung eines kostenlosen Händlerkontos in Paraguay} (Englisch, 2.8 Min)', // 2:49
  'Get started.7': '{listitem}2. {link:media/2023/diy/02.mp4^Einen Online-Shop erstellen} (Englisch, 2 Min)', // 1:55
  'Get started.8': '{listitem}3. {link:media/2023/diy/03.mp4^Versandzonen erstellen} (Englisch, 2.1 Min)', // 2:08
  'Get started.9': '{listitem}4. {link:media/2023/diy/04.mp4^Einen Verkaufsstand aufbauen} (Englisch, 1.5 min)', // 1:30
  'Get started.10': '{listitem}5. {link:media/2023/diy/05.mp4^Produkte zu Ihrem Online-Shop hinzufügen} (Englisch, 4 Min)', // 4:03
  'Get started.11': '{listitem}6. {link:media/2023/diy/06.mp4^Auftragsbearbeitung freigeben} (Englisch, 39 Sek)', // 
  'Get started.12': '{listitem}7. {link:media/2023/diy/07.mp4^Hinzufügen Ihres Geschäfts zum Online-Markt} (Englisch, 3.3 Min)', // 3:16
  'Get started.13': '{listitem}8. {link:media/2023/diy/08.mp4^Beispiel für einen Kundenkauf} (Englisch, 2.3 Min)', // 2:18
  'Get started.14': '{listitem}9. {link:media/2023/diy/09.mp4^Prüfen Sie Ihre Bestellungen} (Englisch, 2.3 Min)', // 2:19
  'Get started.15': '{listitem}10. {link:media/2023/diy/10.mp4^Schlussbemerkungen} (Englisch, 3 Min)', // 2:54

'market-naddr-link': `https://mercado.satoshidnc.com`,

'pubkey:casa-vegana': '34132eb2c3debae8d3137612dc3b66055c208c36f30a6f583928917e428abd4d',
'stall:casa-vegana': 'RHmaUSrSRJojinEsLgrVbk',

'pubkey:more-energy': 'e099882085e9f43b5d6171c63c560dbc9c96dc49612590693d1477a4bae2a215',
'stall:more-energy': 'mny7wbCPVVkLzHzXHxbUZb',

}}

export default init_de